import question_1 from "../images/question_1.png";
import question_2 from "../images/question_2.png";
import question_3 from "../images/question_3.png";
import question_4 from "../images/question_4.png";

import { shuffleArray } from "../../../../../../../utils/helpers/general";

export const PICK_PRIORITY_LIMIT = 5;

export const PRIORITIES_DEFAULT_STATE = {
  totalSteps: 4,
  step1: {
    imageSrc: question_1,
    stepIndex: 1,
    title: "Prioritize the cards below from top to bottom.",
    values: shuffleArray([
      {
        label: "Developing my skills / passions",
        type: "Spending",
      },
      {
        label: "Maximizing health / wellness",
        type: "Spending",
      },
      {
        label: "Spending guilt-free",
        type: "Spending",
      },
      {
        label: "Devoting time to loved ones",
        type: "Spending",
      },
    ]),
  },
  step2: {
    imageSrc: question_2,
    stepIndex: 2,
    title: "Prioritize the cards below from top to bottom.",
    values: shuffleArray([
      {
        label: "Minimizing debt / taxes",
        type: "Giving",
      },
      {
        label: "Preparing for emergencies",
        type: "Giving",
      },
      {
        label: "Removing burdens from my family",
        type: "Giving",
      },
      {
        label: "Feeling stable / secure",
        type: "Giving",
      },
    ]),
  },
  step3: {
    imageSrc: question_3,
    stepIndex: 3,
    title: "Prioritize the cards below from top to bottom.",
    values: shuffleArray([
      {
        label: "Creating opportunities for others",
        type: "Investing",
      },
      {
        label: "Giving generously",
        type: "Investing",
      },
      {
        label: "Supporting family / friends",
        type: "Investing",
      },
      {
        label: "Volunteering my time / talent",
        type: "Investing",
      },
    ]),
  },
  step4: {
    imageSrc: question_4,
    stepIndex: 4,
    title: "Prioritize the cards below from top to bottom.",
    values: shuffleArray([
      {
        label: "Leaving a legacy",
        type: "Saving",
      },
      {
        label: "Pursuing meaningful work",
        type: "Saving",
      },
      {
        label: "Educating my loved ones",
        type: "Saving",
      },
      {
        label: "Making work optional",
        type: "Saving",
      },
    ]),
  },
};
