import IconBlocked from "../../icons/IconBlocked";
import IconAskPenny from "../../icons/IconAskPenny";
import IconClient from "../../icons/IconClient";
import IconDashboard from "../../icons/IconDashboard";
import IconGoalsNew from "../../icons/IconGoalsNew";
import IconLead from "../../icons/IconLead";
import IconMarketing from "../../icons/IconMarketing";
import IconProduct from "../../icons/IconProduct";
import Proposal from "../../icons/Proposal";
import User from "../../icons/User";
import Users from "../../icons/Users";

import { showTabNewClients } from "../../ecosystems/next/helpers";

export const SIDEBAR_TABS = {
  access: {
    affiliate: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: IconAskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNext: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: IconAskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNextProportal: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: IconAskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateProportal: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: IconAskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    level: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: IconClient,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: IconProduct,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: IconMarketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    levelNext: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: IconClient,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: IconProduct,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: IconMarketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    next: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    nextProportal: [
      {
        Icon: IconDashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    proportal: [
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: IconLead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: IconClient,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: IconBlocked,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
  },
  astor: {
    admin: [
      {
        path: "/astor-admin-portal/advisors",
        Icon: User,
        text: "Advisors",
      },
      {
        path: "/astor-admin-portal/products",
        Icon: IconProduct,
        text: "Products",
      },
      {
        path: "/astor-admin-portal/proposal",
        Icon: Proposal,
        text: "Proposal",
      },
      {
        path: "/astor-admin-portal/marketing",
        Icon: IconMarketing,
        text: "Marketing",
      },
      {
        path: "/astor-admin-portal/clients",
        Icon: IconClient,
        text: "Clients",
      },
    ],
    advisor: [
      {
        path: "/astor-advisor-portal/dashboard",
        Icon: User,
        text: "Dashboard",
      },
      {
        path: "/astor-advisor-portal/leads",
        Icon: Users,
        text: "Leads",
      },
      {
        path: "/astor-advisor-portal/clients",
        Icon: IconClient,
        text: "Clients",
      },
      {
        path: "/astor-advisor-portal/products",
        Icon: IconProduct,
        text: "Products",
      },
      {
        path: "/astor-advisor-portal/marketing",
        Icon: IconMarketing,
        text: "Marketing",
      },
    ],
  },
  disabled: [
    {
      disabled: true,
      Icon: IconBlocked,
      path: "/next-advisor-portal/dashboard",
      text: "Dashboard",
    },
    {
      disabled: true,
      Icon: IconBlocked,
      path: "/next-advisor-portal/leads",
      text: "Leads",
    },
    {
      disabled: true,
      Icon: IconBlocked,
      path: "/next-advisor-portal/proposals",
      text: "Clients",
    },
    {
      disabled: true,
      Icon: IconBlocked,
      path: "/next-advisor-portal/products",
      text: "Products",
    },
    {
      disabled: true,
      Icon: IconBlocked,
      path: "/next-advisor-portal/marketing",
      text: "Marketing",
    },
  ],
  proposal: {
    level: [
      {
        Icon: IconGoalsNew,
        path: "/proposal/dashboard",
      },
    ],
  },
};
