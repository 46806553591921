import { Button, theme } from "antd";
import { openProposalWithConfig } from "../utils/helpers/specialized";
import { useState } from "react";

const ButtonDemoMode = ({
  background,
  borderColor,
  color,
  organization,
  userManagerEmail,
  width,
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    openProposalWithConfig({
      organization,
      userManagerEmail,
    });

    setTimeout(() => setLoading(false), 5000);
  };

  return (
    <Button
      disabled={loading}
      onClick={handleClick}
      shape="round"
      size="small"
      style={{
        width,
        background,
        color: color ?? token.colorPrimary,
        borderColor: borderColor ?? token.colorPrimary,
      }}
    >
      Demo Mode
    </Button>
  );
};

export default ButtonDemoMode;
