import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../context";
import { Button, ConfigProvider, Flex, Form, theme } from "antd";
import GoalsUserTab from "./components/GoalsUserTab";
import TabUser from "../../ecosystems/next/components/ModalNextManagedUserData/components/TabUser";

import StyledModal from "./styles";

import { tableItemTypes } from "./constants";
import {
  DEFAULT_RETIREMENT_AGE,
  NEW_CLIENT_DEFAULT_DATA,
} from "../../utils/constants";

import {
  addManagedUser,
  addManagedUserExpanded,
  openGoalsManagedUser,
  openManagedUser,
  openProposal,
  setLastAdvisorLogin,
  updateManagedUser,
} from "../../utils/requests/manager";
import {
  isAdminView,
  isOrganizationAstor,
  isOrganizationOneascent,
} from "../../utils/helpers/specialized";
import { isValidEmail } from "../../utils/helpers/general";

import Close from "../../icons/Close";
import { ReactComponent as OpenClientViewIcon } from "./images/open_client_view.svg";

const ModalManagedUserData = ({ handleClose, isLevelView, open }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [editView, setEditView] = useState(false);

  const isMarriedFormValue = Form.useWatch("maritalStatus", form) === "married";

  const organization = state.orgName ?? state.organization?.name;

  const isGoalsPlanningType =
    isLevelView ||
    (state.access?.affiliate &&
      !state.access?.level &&
      !state.access?.next &&
      !state.access?.proportal) ||
    (state.activeEmail &&
      state.managedUsers.find(it => it.email === state.activeEmail)?.valueMap
        ?.leadInitial?.planningType === "goals");

  useEffect(() => {
    if (state.activeEmail) {
      const existedUserData = state.managedUsers.find(
        it => it.email === state.activeEmail
      );

      if (existedUserData) {
        setEditView(true);

        form.setFieldsValue({
          ...existedUserData,
          oldEmail: existedUserData.email,
          enableOnboardingQuestions:
            existedUserData.enableOnboardingQuestions ??
            existedUserData.valueMap?.guideInitialData
              ?.enableOnboardingQuestions ??
            true,
          retirementAge:
            existedUserData.retirementAge ??
            existedUserData.valueMap?.guideInitialData?.retirementAge ??
            DEFAULT_RETIREMENT_AGE,
          yearBorn:
            existedUserData.birthYear ??
            existedUserData.yearBorn ??
            existedUserData.valueMap?.guideInitialData?.yearBorn,
          maritalStatus: existedUserData.valueMap?.guideInitialData?.isMarried
            ? "married"
            : "single",
          spouseEmail: existedUserData.valueMap?.guideInitialData?.spouseEmail,
          spouseFirstName:
            existedUserData.valueMap?.guideInitialData?.spouseFirstName,
          spouseLastName:
            existedUserData.valueMap?.guideInitialData?.spouseLastName,
        });
      }
    } else {
      setEditView(false);
      form.resetFields();
      form.setFieldsValue(NEW_CLIENT_DEFAULT_DATA);
    }
  }, [open]);

  const onFinish = () => {
    const formValues = form.getFieldsValue();

    if (!isGoalsPlanningType) {
      if (!isValidEmail(formValues.email)) {
        state.showWarning("Invalid email address, please re-enter.");
        return;
      } else if (!formValues.firstName) {
        state.showWarning("Enter new user First name, please.");
        return;
      } else if (!formValues.lastName) {
        state.showWarning("Enter new user Last name, please.");
        return;
      } else if (
        formValues.yearBorn &&
        formValues.startDate &&
        formValues.yearBorn > dayjs(formValues.startDate.$d).format("YYYY")
      ) {
        state.showWarning("Birth Year could not be bigger, than Start Date.");
        return;
      } else if (formValues.maritalStatus === "married") {
        if (!isValidEmail(formValues.spouseEmail)) {
          state.showWarning("Invalid Spouse email address, please re-enter.");
          return;
        } else if (!formValues.spouseFirstName || !formValues.spouseLastName) {
          state.showWarning("Spouse data are required");
          return;
        }
      }
    }

    state.setKeyValue("loading", true);

    if (formValues.oldEmail) {
      updateManagedUser(formValues)
        .then(() => {
          state.setAdminData().then(() => {
            handleClose();
            state.showSuccess("Client data updated");
          });
        })
        .catch(console.log);
    } else {
      if (!formValues.planType || formValues.planType === "basic") {
        const requestBody = {
          doNotNotifyManagedUser: true,
          maritalStatus: "single",
          scenarioName: "Scenario 1",
          startDate: formValues.startDate?.$d ?? new Date(),
          planningType: "goals",
          ...formValues,
        };

        addManagedUser(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            handleClose();

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      } else {
        const requestBody = {
          ...formValues,
          startDate: formValues.startDate?.$d,
          accounts: JSON.stringify(
            formValues.accounts.map(accountData => ({
              account_name: accountData.accountType,
              account_type: tableItemTypes.find(
                it => it.title === accountData.accountType
              ).type,
              starting_balance: accountData.startingBalance,
              growth: accountData.growth,
              start_age: accountData.startAge,
              end_age: accountData.endAge,
            }))
          ),
          incomes: JSON.stringify(
            formValues.incomes.map(incomeData => ({
              income_name: incomeData.incomeType,
              income_type: tableItemTypes.find(
                it => it.title === incomeData.incomeType
              ).type,
              annual_amount: incomeData.annualAmount,
              start_age: incomeData.startAge,
              end_age: incomeData.endAge,
            }))
          ),
          expenses: JSON.stringify(
            formValues.expenses.map(expenseData => ({
              expense_name: expenseData.expenseType,
              expense_type: tableItemTypes.find(
                it => it.title === expenseData.expenseType
              ).type,
              amount: expenseData.amount,
              frequency: expenseData.frequency,
              start_age: expenseData.startAge,
              end_age: expenseData.endAge,
            }))
          ),
        };

        addManagedUserExpanded(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            handleClose();

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      }
    }
  };

  const handleOpenClientView = () => {
    if (
      state.access?.level ||
      isOrganizationOneascent(organization) ||
      isOrganizationAstor(organization)
    ) {
      // proposal user
      openProposal({
        userEmail: state.activeEmail,
        organization: state.organization?.name,
      });
    } else if (isGoalsPlanningType) {
      // goals user
      openGoalsManagedUser(state.activeEmail);
    } else {
      // cashflow user
      openManagedUser(state.activeEmail);
    }

    setLastAdvisorLogin(state.activeEmail).then(() => state.setAdminData());
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: token.colorRadioButton,
          },
        },
      }}
    >
      <StyledModal
        className={"add-client-modal"}
        closeIcon={<Close />}
        footer={
          !isOrganizationOneascent(organization) &&
          state.activeEmail &&
          (!isGoalsPlanningType || state.access?.affiliate) ? (
            ""
          ) : (
            <Flex gap={16}>
              <Button
                disabled={state.loading || isAdminView()}
                form={"clientInfoForm"}
                onClick={onFinish}
                htmlType={"button"}
                shape={"round"}
                type={"primary"}
              >
                {editView ? "Update" : "Create User"}
              </Button>
              <Button
                htmlType={"button"}
                onClick={handleClose}
                shape={"round"}
                style={{ background: "#E6F4EF", color: token.colorPrimary }}
                type={"text"}
              >
                Close
              </Button>
            </Flex>
          )
        }
        onCancel={handleClose}
        open={open}
        title={
          editView ? (
            <Flex
              align={"center"}
              justify={"space-between"}
              style={{ paddingRight: 40 }}
            >
              <b style={{ fontSize: 18, color: "#1B5568" }}>
                {form.getFieldValue("firstName")}{" "}
                {form.getFieldValue("lastName")}
              </b>
              <Button
                onClick={handleOpenClientView}
                shape={"round"}
                size={"small"}
                style={{
                  width: 170,
                  background: "#E6F4EF",
                  color: "#1B5568",
                }}
                type={"primary"}
              >
                <Flex align={"center"} gap={4}>
                  <span>Open Advisor View</span>
                  <OpenClientViewIcon />
                </Flex>
              </Button>
            </Flex>
          ) : (
            "Client Details"
          )
        }
        width={568}
      >
        <Form
          form={form}
          id={"clientInfoForm"}
          layout={"vertical"}
          onFinish={onFinish}
        >
          {isGoalsPlanningType ? (
            <GoalsUserTab />
          ) : (
            <TabUser
              isOneascent={isOrganizationOneascent(organization)}
              managerAccess={state.access}
              isMarriedFormValue={isMarriedFormValue}
            />
          )}
        </Form>
      </StyledModal>
    </ConfigProvider>
  );
};

export default ModalManagedUserData;
