import { useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Button, Dropdown, Flex, theme } from "antd";

import { StyledCard } from "./styles";

import { DEFAULT_PRODUCT_ACCENT_COLOR } from "../../utils/constants";
import { ReactComponent as CheckOutlined } from "../../icons/check_outlined.svg";

const CardProduct = ({
  actions,
  handleCardClick,
  handleCloseButtonClick,
  handleSecondaryButtonActivate,
  height = "fit-content",
  inactive,
  isActionButtonActive,
  isSecondaryButtonActive,
  labels,
  minHeight,
  onMouseEnter,
  onMouseLeave,
  product,
  showCloseButton,
  showChart,
  showObjective,
  showSecondaryButton,
  width = "245px",
  maxWidth = "380px",
  minWidth = "185px",
  viewMode,
}) => {
  const { token } = theme.useToken();
  const chartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      const storedData = localStorage.getItem("pieChartSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const pieChartSvg = chartRef?.current?.chart?.getSVG();

          pieChartSvg && localStorage.setItem("pieChartSvg", pieChartSvg);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  const handleMenuClick = ({ item }) => item.props.onSelect(product);

  const menuProps = {
    items: actions,
    onClick: it => {
      it.domEvent.stopPropagation();
      handleMenuClick(it);
    },
  };

  const chartConfig = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      spacingTop: 0,
      width: 260,
      height: 250,
    },
    colors: token.chartColors,
    tooltip: {
      outside: true,
      style: {
        zIndex: 9999,
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 116,
        innerSize: "60%",
        showInLegend: true,
        center: ["50%", "50%"],
        borderWidth: 0,
        dataLabels: {
          connectorWidth: 0,
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: 2,
          position: "left",
          style: {
            fontSize: 16,
            fontWeight: 400,
            color: "#516474",
          },
        },
      },
    },
    legend: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Percent",
        colorByPoint: true,
        data: product?.assets?.map(it => ({
          name: it.assetName,
          y: it.investmentPercent,
        })),
      },
    ],
  };

  const ButtonClose = () =>
    showCloseButton && (
      <Button
        onClick={e => {
          e.stopPropagation();
          handleCloseButtonClick(product?._id);
        }}
        shape={"round"}
        size={"small"}
        style={{
          marginRight: "-6px",
          marginTop: "-6px",
        }}
      >
        Remove
      </Button>
    );

  const ButtonSecondary = () =>
    showSecondaryButton &&
    (isSecondaryButtonActive ? (
      <Button disabled icon={<CheckOutlined />} shape={"round"}>
        Selected
      </Button>
    ) : (
      <Button
        onClick={e => {
          e.stopPropagation();
          handleSecondaryButtonActivate(product?._id);
        }}
        shape={"round"}
      >
        Add to Proposal
      </Button>
    ));

  const LabelsTop = () => (
    <span
      className={"top-label"}
      dangerouslySetInnerHTML={{ __html: product?.assetManager }}
    />
  );

  const LabelsBottom = () => (
    <div style={{ color: token.colorBlack }}>
      {labels
        .filter(it => !!it)
        .map((label, index) => (index === 0 ? label : ` • ${label}`))}
    </div>
  );

  const NavbarActions = () =>
    actions ? (
      actions.length > 1 ? (
        <Dropdown menu={menuProps} trigger={["click"]}>
          <Button
            onClick={e => e.stopPropagation()}
            size={"small"}
            shape={"round"}
            style={{ borderColor: "transparent" }}
          >
            Options
          </Button>
        </Dropdown>
      ) : isActionButtonActive ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            actions[0].onSelect(product);
          }}
          shape={"round"}
          style={{
            background: token.tenantColorPrimary,
            color: "#FFFFFF",
          }}
        >
          {actions[0].buttonActiveCopy}
        </Button>
      ) : (
        <Button
          shape={"round"}
          style={{ width: actions[0].buttonWidth }}
          onClick={e => {
            e.stopPropagation();
            actions[0].onSelect(product);
          }}
        >
          {actions[0].buttonCopy}
        </Button>
      )
    ) : (
      ""
    );

  return (
    <StyledCard
      accent_color={product?.color ?? DEFAULT_PRODUCT_ACCENT_COLOR}
      className={"product-card-container"}
      direction={"vertical"}
      height={height}
      onClick={() => handleCardClick && handleCardClick(product?._id)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity: inactive && 0.3 }}
      min_height={minHeight}
      max_width={maxWidth}
      min_width={minWidth}
      token={token}
      width={width}
    >
      <Flex gap={40} justify={"space-between"} vertical>
        <Flex gap={13} vertical>
          <Flex
            align={"center"}
            justify={"space-between"}
            style={{ height: 19, width: "100%" }}
            wrap={"nowrap"}
          >
            <LabelsTop />
            <ButtonClose />
          </Flex>
          <div className={"title"}>{product?.name}</div>
          <LabelsBottom />
          {showObjective && (
            <span className={"objective"}>{product?.objective}</span>
          )}
        </Flex>

        <Flex align={"flex-end"} justify={"space-between"}>
          <NavbarActions />
          <ButtonSecondary />
        </Flex>
      </Flex>

      {showChart && (
        <Flex>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartConfig}
            ref={chartRef}
          />
        </Flex>
      )}
    </StyledCard>
  );
};

export default CardProduct;
