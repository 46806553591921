import { useMediaQuery } from "react-responsive";
import { Row, Skeleton, Space, theme } from "antd";
import CardSkeleton from "../../atoms/CardSkeleton";

const SkeletonDashboard = ({ loading, children }) => {
  const { token } = theme.useToken();
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });

  return loading ? (
    <Row gutter={[30, 30]}>
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 2, width: [170, 340] }}
      />
      <Space size={32} wrap={isTablet}>
        <CardSkeleton loading={loading} width={300} />
        <CardSkeleton loading={loading} width={300} />
        <CardSkeleton loading={loading} width={300} />
      </Space>
    </Row>
  ) : (
    children
  );
};

export default SkeletonDashboard;
