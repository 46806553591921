import { Gauge } from "@ant-design/plots";
import { Typography } from "antd";
import CardSkeleton from "../../../../../../atoms/CardSkeleton";

import { getGaugeConfig } from "../../../../../../utils/charts";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helpers/general";
import { isGrowthTypeRiskTolerance } from "../../../../../../utils/helpers/specialized";

const GaugeSection = ({
  investmentObjective,
  productLiveSimulation,
  loading,
}) => (
  <CardSkeleton loading={loading} width={170} height={235}>
    {isGrowthTypeRiskTolerance(investmentObjective) ? (
      <div
        className={"chance-of-success-container"}
        style={{ textAlign: "center" }}
      >
        <Gauge {...getGaugeConfig(0.8)} />
        <Typography.Paragraph style={{ margin: "9px 0 0" }}>
          this investment returns between
        </Typography.Paragraph>
        {productLiveSimulation?.props && (
          <Typography.Text style={{ fontSize: 20 }} strong>
            {getCurrencyValueWithRoundSign(
              productLiveSimulation.props.trajectoryPercentile10thMC[
                productLiveSimulation.props.trajectoryPercentile10thMC.length -
                  1
              ][1]
            )}
            {" - "}
            {getCurrencyValueWithRoundSign(
              productLiveSimulation.props.trajectoryPercentile90thMC[
                productLiveSimulation.props.trajectoryPercentile90thMC.length -
                  1
              ][1]
            )}
          </Typography.Text>
        )}
      </div>
    ) : (
      <div
        className={"chance-of-success-container"}
        style={{ textAlign: "center" }}
      >
        <Gauge
          {...getGaugeConfig(
            (productLiveSimulation?.props?.lastQuickSim === 100
              ? 99
              : productLiveSimulation?.props?.lastQuickSim) / 100
          )}
        />
        <Typography.Paragraph style={{ marginTop: 9 }}>
          this investment will last through retirement
        </Typography.Paragraph>
      </div>
    )}
  </CardSkeleton>
);

export default GaugeSection;
