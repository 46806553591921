import styled from "styled-components";

const StyledCard = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 12px;
  border-bottom: 4px solid ${({ color }) => color};
  border-left: 1px solid ${({ color }) => color};
  border-right: 1px solid ${({ color }) => color};
  border-top: 1px solid ${({ color }) => color};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 12px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .index-value {
    color: #1c5d9a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;

export default StyledCard;
