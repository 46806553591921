import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context";
import Overlay from "../../../../atoms/Overlay";
import ViewProposalGoals from "../../component/ViewProposalGoals";
import ViewProposalGoal from "../../component/ViewProposalGoal";

const PageProposalGoals = () => {
  const location = useLocation();
  const [state] = useContext(State);

  useEffect(() => {
    state.setKeyValue("proposalViewMode", "goals");
  }, [location]);

  const handleAddGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("proposalViewMode", "goal");
  };

  const handleCloseGoal = () => {
    state.setKeyValue("activeGoal", undefined);
    state.setKeyValue("proposalViewMode", "goals");
  };

  const handleCopyGoal = activeGoal => {
    state.setKeyValue("activeGoal", activeGoal);
    state.setKeyValue("proposalViewMode", "goal");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);
    }, 1000);
  };

  const handleViewGoal = goalId => {
    state.setKeyValue("activeGoal", goalId);
    state.setKeyValue("proposalViewMode", "goal");
  };

  return (
    <Overlay loading={state.loading || state.proposalViewMode === "pdf"}>
      {state.getPreferenceValue("productMap") &&
      state.proposalViewMode === "goals" ? (
        <ViewProposalGoals
          handleAddGoal={handleAddGoal}
          handleCopyGoal={handleCopyGoal}
          handleViewGoal={handleViewGoal}
          productsList={state.productsList}
        />
      ) : (
        <ViewProposalGoal
          activeGoal={state.activeGoal}
          handleCloseGoal={handleCloseGoal}
          productsList={state.productsList}
        />
      )}
    </Overlay>
  );
};

export default PageProposalGoals;
