import PageProposalDashboard from "../page/PageProposalDashboard";
import PageProposalGoals from "../../proposal/page/PageProposalGoals";

import IconGoals from "../../../icons/IconGoals";
import IconGoalsDashboard from "../../../icons/IconGoalsDashboard";

export default [
  {
    path: "/oneascent-proposal/dashboard",
    Component: PageProposalDashboard,
    Icon: IconGoalsDashboard,
    text: "Dash",
  },
  {
    path: "/oneascent-proposal/goals",
    Component: PageProposalGoals,
    Icon: IconGoals,
    text: "Investments",
  },
].filter(it => !it.hidden);
