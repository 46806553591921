import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context";
import { Button, Flex, Form, Image, InputNumber, Radio, theme } from "antd";
import Bullet from "../../../../atoms/Bullet";

import StylesContainer from "./styles";

import { defaultProposalData, listStepData } from "./constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helpers/general";

import { ReactComponent as Logo } from "./images/logo.svg";
import risk_line from "./images/risk_line.svg";

const QuestionnaireContentLevel = ({
  handleClose,
  handleCreateProposal,
  open,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  const investmentObjective = Form.useWatch("investmentObjective", form);

  const { title, description, HeroIcon, heroTitle, heroDescription } =
    listStepData[currentStepIndex];

  useEffect(() => {
    if (open) {
      setCurrentStepIndex(0);
      form.setFieldsValue(defaultProposalData);
    }
  }, [open]);

  const handleSubmit = () => {
    handleCreateProposal({
      ...defaultProposalData,
      ...form.getFieldsValue(),
    });
    setCurrentStepIndex(0);
  };

  return (
    <StylesContainer token={token} vertical>
      <header>
        <Logo className={"logo"} />
      </header>
      <Flex align={"start"} className={"body"} justify={"space-between"}>
        <Flex className={"form-container"} justify={"space-between"} vertical>
          <div>
            <h2 className="title">{title}</h2>
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Form id={"levelQuestionnaireForm"} form={form} layout={"vertical"}>
              <Form.Item
                hidden={currentStepIndex !== 0}
                name={"investmentObjective"}
              >
                <Radio.Group>
                  <Flex gap={18} style={{ padding: "60px 32px" }} vertical>
                    <Radio value={"growth"}>Drive Growth</Radio>
                    <Radio value={"income"}>Drive Income</Radio>
                  </Flex>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                hidden={currentStepIndex !== 1}
                name={"riskTolerance"}
                style={{ marginTop: isSmallScreen ? 32 : 53 }}
              >
                <Radio.Group style={{ paddingLeft: isSmallScreen ? 32 : 0 }}>
                  <Flex
                    gap={18}
                    justify={"space-between"}
                    vertical={isSmallScreen}
                  >
                    <Radio value={"Conservative"}>Conservative</Radio>
                    <Radio value={"Moderate"}>Moderate</Radio>
                    <Radio value={"Aggressive"}>Aggressive</Radio>
                  </Flex>
                  <Image
                    className={"risk-line-image"}
                    preview={false}
                    src={risk_line}
                    style={{ margin: "16px 0 53px" }}
                    width={491}
                  />
                  <Radio
                    style={{
                      marginBottom: 20,
                      marginTop: isSmallScreen ? 18 : 0,
                    }}
                    value={"unknown"}
                  >
                    I don't know
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                hidden={currentStepIndex !== 2}
                label={"Investment Amount"}
                name={"investmentAmount"}
                style={{ marginTop: 55 }}
              >
                <InputNumber
                  formatter={getCurrencyValue}
                  max={100000000}
                  min={0}
                  onClick={selectValue}
                  parser={cleanCurrencyValue}
                  step={1000}
                />
              </Form.Item>
              <Form.Item
                hidden={
                  currentStepIndex !== 2 || investmentObjective !== "income"
                }
                label={"Year to Start Withdrawals"}
                name={"yearToStartWithdrawals"}
              >
                <InputNumber max={80} min={1} onClick={selectValue} />
              </Form.Item>
              <Form.Item
                hidden={currentStepIndex !== 2}
                label={"Length of Investment"}
                name={"investmentDuration"}
                style={{ marginBottom: 55 }}
              >
                <InputNumber max={80} min={1} onClick={selectValue} />
              </Form.Item>
            </Form>
            <Flex gap={23} justify={"space-between"} vertical={isSmallScreen}>
              {currentStepIndex === 0 ? (
                <Button
                  block={isSmallScreen}
                  onClick={handleClose}
                  shape={"round"}
                  size={"large"}
                  style={{ order: isSmallScreen ? 2 : 1 }}
                  type={"text"}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  block={isSmallScreen}
                  onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                  shape={"round"}
                  size={"large"}
                  style={{ order: isSmallScreen ? 2 : 1 }}
                  type={"text"}
                >
                  Back
                </Button>
              )}
              {currentStepIndex === 2 ? (
                <Button
                  block={isSmallScreen}
                  disabled={state.loading}
                  onClick={handleSubmit}
                  shape={"round"}
                  size={"large"}
                  style={{ order: isSmallScreen ? 1 : 2 }}
                  type={"primary"}
                >
                  Let’s Go
                </Button>
              ) : (
                <Button
                  block={isSmallScreen}
                  onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                  shape={"round"}
                  size={"large"}
                  style={{ order: isSmallScreen ? 1 : 2 }}
                  type={"primary"}
                >
                  Continue
                </Button>
              )}
            </Flex>
          </div>

          <Flex
            gap={12}
            justify={"center"}
            style={{ marginTop: isSmallScreen ? 13 : 40 }}
          >
            {Array(listStepData.length)
              .fill(null)
              .map((_, index) => (
                <Bullet
                  color={currentStepIndex === index ? "#4F8083" : "#749B9E50"}
                  key={index}
                  size={12}
                />
              ))}
          </Flex>
        </Flex>
        <section className={"hero-section"}>
          <Flex
            align={"center"}
            className={"icon-container"}
            justify={"center"}
          >
            <HeroIcon />
          </Flex>
          <div className={"title"}>{heroTitle}</div>
          <div
            className={"description"}
            dangerouslySetInnerHTML={{ __html: heroDescription }}
          />
        </section>
      </Flex>
    </StylesContainer>
  );
};

export default QuestionnaireContentLevel;
