import { createContext, useContext, useEffect, useMemo, useState } from "react";
import html2pdf from "html2pdf.js";
import State from "../../../../context";
import { Button, Checkbox, Flex, Image, Table } from "antd";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PdfContentHolder from "./component/PdfContentHolder";

import StyledModal from "./styles";

import { LIST_PDF_PAGES_DATA } from "../../constant";

import { getClonedNode } from "../../../../utils/helpers/specialized";

import Close from "../../../../icons/Close";
import { ReactComponent as Reorder } from "./image/icon_reorder.svg";

import { PDF_CONVERTER_OPTIONS } from "../../../../utils/constants";

const RowContext = createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      icon={<Reorder />}
      type="text"
      size="small"
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = props => {
  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const ModalCreateReport = ({ onCancel, open }) => {
  const [state] = useContext(State);
  const [dataSource, setDataSource] = useState(
    LIST_PDF_PAGES_DATA.map(it => ({
      ...it,
      disabled: true,
      visible: false,
    }))
  );

  useEffect(() => {
    if (open) {
      let visiblePagesList = [];

      //get visible pdf pages based on filling of the application by custom data
      if (state.getPreferenceValue("prospectObjective")) {
        if (state.getPreferenceValue("productMap")) {
          // saved ipq and proposal
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "proposal_product_selection",
            "ipq",
            "fact_sheets",
            "disclosures",
          ];
        } else {
          // saved ipq only
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "ipq",
            "disclosures",
          ];
        }
      }

      if (state.getPreferenceValue("perspectiveMy")) {
        // saved perspectives
        visiblePagesList = [
          ...visiblePagesList,
          "cover_page",
          "introduction",
          "perspectives",
          "disclosures",
        ];
      }

      if (state.getPreferenceValue("prioritiesFamily")) {
        // saved priorities
        visiblePagesList = [
          ...visiblePagesList,
          "cover_page",
          "introduction",
          "priorities",
          "disclosures",
        ];
      }

      if (state.getPreferenceValue("milestonesQuestions")) {
        // saved milestones
        visiblePagesList = [
          ...visiblePagesList,
          "cover_page",
          "introduction",
          "milestones",
          "disclosures",
        ];
      }

      setDataSource(lastState =>
        lastState.map(elem =>
          [...new Set(visiblePagesList)].includes(elem.key)
            ? {
                ...elem,
                visible: true,
                disabled: false,
              }
            : {
                ...elem,
                visible: false,
                disabled: true,
              }
        )
      );
    }
  }, [open]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource(prevState => {
        const activeIndex = prevState.findIndex(
          record => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          record => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const handleGeneratePdf = () => {
    state.setKeyValue("showOverlay", true);
    state.showSuccess("Generating PDF for you");

    let pdf = html2pdf();

    dataSource
      .filter(pageData => pageData.visible)
      .map((pageData, index) => {
        if (pageData.nodeIdCluster?.length) {
          pageData.nodeIdCluster.map((nodeId, nodeClusterIndex) => {
            const pageNode = getClonedNode(document.getElementById(nodeId));

            if (!pageNode) {
              console.log(`Page with id '${nodeId}' missed`);
              return;
            }

            if (index === 0 && nodeClusterIndex === 0) {
              pdf = pdf
                .set(PDF_CONVERTER_OPTIONS)
                .from(pageNode)
                .toContainer()
                .toCanvas()
                .toPdf();
            } else {
              pdf = pdf
                .get("pdf")
                .then(pdf => pdf.addPage())
                .set(PDF_CONVERTER_OPTIONS)
                .from(pageNode)
                .toContainer()
                .toCanvas()
                .toPdf();
            }
          });
        } else {
          const pageNode = getClonedNode(
            document.getElementById(pageData.nodeId)
          );

          if (!pageNode) {
            console.log(`Page with id '${pageData.nodeId}' missed`);
            return;
          }

          if (index === 0) {
            pdf = pdf
              .set(PDF_CONVERTER_OPTIONS)
              .from(pageNode)
              .toContainer()
              .toCanvas()
              .toPdf();
          } else {
            pdf = pdf
              .get("pdf")
              .then(pdf => pdf.addPage())
              .set(PDF_CONVERTER_OPTIONS)
              .from(pageNode)
              .toContainer()
              .toCanvas()
              .toPdf();
          }
        }
      });

    pdf = pdf.output("bloburl").then(function (pdf) {
      let pdfWindow = window.open("", "_blank");
      pdfWindow.document.write(
        "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
      );
      pdfWindow.document.title = "OneAscent PDF";

      state.setKeyValue("showOverlay", false);
    });
  };

  const toggleVisibility = (key, isChecked) =>
    setDataSource(lastState =>
      lastState.map(it =>
        it.key === key
          ? {
              ...it,
              visible: isChecked,
            }
          : it
      )
    );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"}>
            Cancel
          </Button>
          <Button onClick={handleGeneratePdf} shape={"round"}>
            Generate PDF
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"PDF Options"}
      width={1270}
      zIndex={1000}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map(i => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            dataSource={dataSource}
            pagination={false}
            rowKey={"key"}
          >
            <Table.Column
              dataIndex={"Show"}
              render={(_, allValues) => (
                <Checkbox
                  checked={allValues.visible}
                  disabled={allValues.disabled}
                  onChange={e =>
                    toggleVisibility(allValues.key, e.target.checked)
                  }
                />
              )}
              title={"Show"}
              width={68}
            />
            <Table.Column
              className={"preview-image-cell"}
              dataIndex={"previewImage"}
              render={src => <Image preview={false} src={src} />}
              width={50}
            />
            <Table.Column dataIndex={"label"} title={"Section"} />
            <Table.Column
              align={"center"}
              className={"drag-icon-cell"}
              key={"sort"}
              render={() => <DragHandle />}
              title={"Order"}
              width={70}
            />
          </Table>
        </SortableContext>
      </DndContext>
      <PdfContentHolder />
    </StyledModal>
  );
};

export default ModalCreateReport;
