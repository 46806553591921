import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import MenuActions from "../MenuActions";

import StylesContainer from "./styles";

import { ReactComponent as IconAlign } from "../../image/icon_align.svg";
import { ReactComponent as IconAvoid } from "../../image/icon_avoid.svg";

const CardInvestments = ({ proposalData }) => {
  const navigate = useNavigate();
  const [state] = useContext(State);

  const prospectObjective = state.getPreferenceValue("prospectObjective");

  const handleCreateProposal = () => {
    state.setKeyValue("activeEmail", state._id);
    state.setKeyValue("openModalProposalGuide", true);

    if (prospectObjective?.investmentAmount === null) {
      state.setKeyValue("showOverlay", true);
      // user already passed first part of the quiz, open for him last quiz step
      setTimeout(() => {
        navigate(location.pathname, { state: { stepIndex: 6 } });
        state.setKeyValue("showOverlay", false);
      }, 500);
    }
  };

  const handleNewIpq = () => {
    state.setKeyValue("activeEmail", state._id);
    state.setKeyValue("newIpqMode", true);
    state.setKeyValue("openModalProposalGuide", true);
  };

  const handleViewIpq = () => {
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleViewProposal = () => {
    state.setKeyValue("showOverlay", true);
    navigate("/oneascent-proposal/goals");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);

      if (state.getPreferenceValue("productMap")) {
        // user have saved proposal(s), show Goals page
        state.setKeyValue("proposalViewMode", "goals");
      } else {
        // no saved proposal, show Goal page
        state.setKeyValue("proposalViewMode", "goal");
      }

      state.setKeyValue("showOverlay", false);
    }, 1000);
  };

  const ButtonIpq = () =>
    prospectObjective ? (
      <Flex gap={16}>
        <Button
          onClick={handleViewIpq}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View IPQ
        </Button>
        <MenuActions
          items={[
            {
              key: "take",
              label: "Re-take IPQ",
              onClick: handleNewIpq,
            },
          ]}
        />
      </Flex>
    ) : (
      <Button onClick={handleNewIpq} shape={"round"} type={"primary"}>
        New IPQ
      </Button>
    );

  const ButtonProposal = () =>
    prospectObjective && prospectObjective?.investmentAmount !== null ? (
      <Flex gap={16}>
        <Button
          onClick={handleViewProposal}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View Proposal
        </Button>
        <MenuActions
          items={[
            {
              key: "take",
              label: "Re-take IPQ",
              onClick: handleNewIpq,
            },
          ]}
        />
      </Flex>
    ) : (
      <Button onClick={handleCreateProposal} shape={"round"} type={"primary"}>
        Create Proposal
      </Button>
    );

  const TableAlignAvoid = () => {
    let investmentsToAvoid = prospectObjective?.investmentsToAvoid;
    let investmentsToAlignWith = prospectObjective?.investmentsToAlignWith;

    if (!investmentsToAvoid && !investmentsToAlignWith) return "";

    if (investmentsToAvoid === "null" && investmentsToAlignWith === "null")
      return "";

    if (typeof investmentsToAvoid === "string") {
      investmentsToAvoid = investmentsToAvoid
        .replaceAll("\\", "")
        .replace('"[', "[")
        .replace(']"', "]");

      investmentsToAvoid = JSON.parse(investmentsToAvoid);
    }

    if (typeof investmentsToAlignWith === "string") {
      investmentsToAlignWith = investmentsToAlignWith
        .replaceAll("\\", "")
        .replace('"[', "[")
        .replace(']"', "]");

      investmentsToAlignWith = JSON.parse(investmentsToAlignWith);
    }

    if (!investmentsToAvoid?.length && !investmentsToAlignWith?.length)
      return "";

    return (
      <Flex className={"extra-copy-container"} justify={"space-between"}>
        {investmentsToAvoid?.length ? (
          <Flex gap={8} vertical>
            {investmentsToAvoid.map((label, index) => (
              <Flex align={"center"} gap={8} key={index}>
                <IconAvoid />
                {label}
              </Flex>
            ))}
          </Flex>
        ) : (
          ""
        )}

        {investmentsToAlignWith?.length ? (
          <Flex gap={8} vertical>
            {investmentsToAlignWith.map((label, index) => (
              <Flex align={"center"} gap={8} key={index}>
                <IconAlign />
                {label}
              </Flex>
            ))}
          </Flex>
        ) : (
          ""
        )}
      </Flex>
    );
  };

  return state.getPreferenceValue("productMap") ? (
    // if proposal saved
    <StylesContainer className={"card-next-up"} gap={24} vertical>
      <h5 className={"title"}>Investments</h5>

      <p className={"description"}>
        Our why helps us stay on track. What matters most to you? Should take
        less than 2 minutes per person.
      </p>

      <Flex justify={"space-between"}>
        <Flex className={"values-container"} gap={12} vertical>
          <Flex gap={4} vertical>
            <div className={"label"}>Risk tolerance:</div>
            <div className={"value"}>{proposalData?.riskTolerance}</div>
          </Flex>
          <Flex gap={4} vertical>
            <div className={"label"}>Portfolio:</div>
            <div className={"value"}>{proposalData?.productName}</div>
          </Flex>
        </Flex>

        <Flex justify={"end"} gap={16} vertical>
          {state.userManagerData?.onboardingQuestionnaireType === "IPQ" && (
            <ButtonIpq />
          )}
          <ButtonProposal />
        </Flex>
      </Flex>

      <TableAlignAvoid />
    </StylesContainer>
  ) : (
    <Flex className={"card-next-up"} gap={24} vertical>
      <Flex gap={40}>
        <Flex gap={24} vertical>
          <h5 className={"title"}>Investments</h5>
          <p className={"description"}>
            Do your investments align with your values? Let’s select the right
            portfolio for the right accounts for the right goals.
          </p>
        </Flex>
        <Flex justify={"end"} gap={16} vertical>
          {state.userManagerData?.onboardingQuestionnaireType === "IPQ" && (
            <ButtonIpq />
          )}
          <ButtonProposal />
        </Flex>
      </Flex>

      <TableAlignAvoid />
    </Flex>
  );
};

export default CardInvestments;
