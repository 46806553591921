import { Image } from "antd";
import RadioButton from "../../component/RadioButton";

import { getCurrencyValue } from "../../../../../utils/helpers/general";

import footer from "../ReportPageIpq2/image/footer.svg";

const ReportPageIpq1 = ({ children, prospectObjective }) => (
  <div
    className={"page-container"}
    style={{
      color: "#2A2828",
      fontFamily: "Helvetica, sans-serif",
      height: 1590,
      position: "relative",
      width: 1230,
    }}
    id={"reportPageIpq1"}
  >
    <div
      style={{
        boxSizing: "border-box",
        fontSize: 12,
        lineHeight: "16px",
        padding: "42px 38px",
      }}
    >
      {children}
      <div
        style={{
          borderBottom: "1px solid #BEC8CE",
          color: "#234744",
          fontSize: 16,
          fontWeight: 300,
          margin: "24px 0 16px",
          paddingBottom: 4,
        }}
      >
        About You
      </div>
      <div style={{ display: "flex", gap: 33 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 325,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>Current Age</b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.currentAgeRange === "-45"}
            />
            <span>Under 45</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.currentAgeRange === "45-55"}
            />
            <span>45 - 55</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.currentAgeRange === "56-65"}
            />
            <span>56 - 65</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.currentAgeRange === "66-75"}
            />
            <span>66 - 75</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.currentAgeRange === "75-"}
            />
            <span>Over 75</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            Your expectations of income changes over the next several years.
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.incomeExpectations ===
                "I fear I might lose my job or I plan to retire"
              }
            />
            <span>I fear I might lose my job or I plan to retire.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.incomeExpectations ===
                "I anticipate my income will probably trend downward"
              }
            />
            <span>I anticipate my income will probably trend downward.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.incomeExpectations ===
                "I expect a fairly level income"
              }
            />
            <span>I expect a fairly level income.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.incomeExpectations ===
                "I anticipate a steadily growing income"
              }
            />
            <span>I anticipate a steadily growing income.</span>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #BEC8CE",
          color: "#234744",
          fontSize: 16,
          fontWeight: 300,
          margin: "24px 0 16px",
          paddingBottom: 4,
        }}
      >
        Time Horizon and Investment Experience
      </div>
      <div style={{ display: "flex", gap: 33 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 325,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            When do you plan to begin withdrawal of at least 5% of your
            investments annually?
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.beginWithdrawal === "-3"}
            />
            <span>0 - 3 years</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.beginWithdrawal === "3-5"}
            />
            <span>3 - 5 years</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.beginWithdrawal === "5-10"}
            />
            <span>5 - 10 years</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={prospectObjective?.beginWithdrawal === "10-"}
            />
            <span>More than 10 years</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 385,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            Which Statement best describes your knowledge of investments?
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.investmentKnowledge ===
                "I know virtually nothing about investing"
              }
            />
            <span>I know virtually nothing about investing.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.investmentKnowledge ===
                "My understanding of the markets and investing is minimal"
              }
            />
            <span>
              My understanding of the markets and investing is minimal.
            </span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.investmentKnowledge ===
                "Somewhat knowledgeable. I have a basic comprehension of investing"
              }
            />
            <span>
              Somewhat knowledgable. I have a basic comprehension of investing.
            </span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.investmentKnowledge ===
                "Highly knowledgeable. I have a good understanding of how the stock and bond markets work, and I spend considerable time keeping up with financial matters"
              }
            />
            <span>
              Highly knowledgable. I have a good understanding of how the stock
              and bond markets work, and I spend considerable time keeping up
              with financial matters.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #BEC8CE",
          color: "#234744",
          fontSize: 16,
          fontWeight: 300,
          margin: "3px 0 16px",
          paddingBottom: 4,
        }}
      >
        Returns, Risk, Volatility...
      </div>
      <div style={{ display: "flex", gap: 33 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 325,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            An investment decision involves both returns and risk - the higher
            the potential for returns, the greater the risk of highly volatile
            results, including loss. What influences you the most when making an
            important investment decision?
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decisionInfluence ===
                "I am mostly influenced by the potential loss"
              }
            />
            <span>I am mostly influenced by the potential loss.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decisionInfluence ===
                "I am equally influenced by potential loss or gain"
              }
            />
            <span>I am equally influenced by potential loss or gain.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.decisionInfluence ===
                "I am mostly influenced by the potential gain"
              }
            />
            <span>I am mostly influenced by the potential gain.</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 7,
            width: 385,
          }}
        >
          <b style={{ color: "#2A2828", marginBottom: 9 }}>
            Understanding that volatility has historically been present when
            investing, what is the maximum amount of volatility you can accept
            over a 1 year period and remain invested?
          </b>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.volatilityTolerance ===
                "Any decrease in value would concern me"
              }
            />
            <span>Any decrease in value would concern me.</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.volatilityTolerance ===
                "Up to 10% downside ($100,000 falling to $90,000)"
              }
            />
            <span>Up to 10% downside ($100,000 falling to $90,000).</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.volatilityTolerance ===
                "Up to 20% downside ($100,000 falling to $80,000)"
              }
            />
            <span>Up to 20% downside ($100,000 falling to $80,000).</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.volatilityTolerance ===
                "Up to 30% downside ($100,000 falling to $70,000)"
              }
            />
            <span>Up to 30% downside ($100,000 falling to $70,000).</span>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <RadioButton
              checked={
                prospectObjective?.volatilityTolerance ===
                "I would not worry about performance for that short of a period"
              }
            />
            <span>
              I would not worry about performance for that short of a period.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 7,
          width: 325,
        }}
      >
        <b style={{ color: "#2A2828", marginBottom: 9 }}>
          When the next downturn in the market comes, how long are you prepared
          to ride out the declining market?
        </b>
        <div style={{ display: "flex", gap: 8 }}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "Less than 1 year"
            }
          />
          <span>Less than 1 year</span>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "Between 1-5 years"
            }
          />
          <span>Between 1-5 years</span>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <RadioButton
            checked={
              prospectObjective?.decliningMarketWithholdingPeriod ===
              "More than 5 years"
            }
          />
          <span>More than 5 years</span>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #BEC8CE",
          color: "#234744",
          fontSize: 16,
          fontWeight: 300,
          margin: "24px 0 7px",
          paddingBottom: 4,
        }}
      >
        A bit about you…
      </div>
      <div style={{ display: "flex", gap: 33 }}>
        <div
          style={{
            display: "flex",
            gap: 100,
            width: 325,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <span>Amount</span>
            <b>
              {getCurrencyValue(prospectObjective?.investmentAmount) || "N/A"}
            </b>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <span>Time</span>
            <b>
              {prospectObjective?.investmentDuration
                ? `${prospectObjective?.investmentDuration} Years`
                : "N/A"}
            </b>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 40,
            width: 385,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <span>Annual Investment Contributions</span>
            <b>{getCurrencyValue(prospectObjective?.contributions) || "N/A"}</b>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <span>Combined Household Income</span>
            <b>
              {getCurrencyValue(prospectObjective?.householdIncome) || "N/A"}
            </b>
          </div>
        </div>
      </div>
    </div>
    <footer style={{ bottom: 0, position: "absolute", width: "100%" }}>
      <Image preview={false} src={footer} width={"100%"} />
    </footer>
  </div>
);

export default ReportPageIpq1;
