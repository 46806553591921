import styled from "styled-components";
import PageAdminAdvisors from "../../../pages/PageAdminAdvisors";
import PageAdminProposal from "../../../pages/PageAdminProposal";
import PageAdminClients from "../../../pages/PageAdminClients";
import PageMarketing from "../../../pages/PageMarketing";
import PageProducts from "../../../pages/PageProducts";
import ManagerHeader from "../components/ManagerHeader";

const Body = styled.div`
  padding: 32px 94px 94px 46px;
`;

export default [
  {
    path: "/astor-admin-portal/advisors",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageAdminAdvisors />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-admin-portal/products",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageProducts />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-admin-portal/proposal",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageAdminProposal />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-admin-portal/marketing",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageMarketing />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-admin-portal/clients",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageAdminClients />
        </Body>
      </>
    ),
  },
];
