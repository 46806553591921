import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../context";
import { Avatar, Space, theme } from "antd";
import MenuActions from "../../atoms/MenuActions";

import { logout } from "../../utils/requests/regularApp";
import {
  isPortalOneascentAdvisor,
  openProposalWithConfig,
} from "../../utils/helpers/specialized";

import { UserOutlined } from "@ant-design/icons";

const MenuProfile = ({ logoutLink = "/login" }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state] = useContext(State);

  const getItems = () =>
    [
      {
        key: "1",
        label: (
          <div onClick={() => state.openModal("accountSettingsModal")}>
            Settings
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            onClick={() =>
              openProposalWithConfig({
                organization: state.organization?.name ?? state.orgName,
              })
            }
          >
            Demo Mode
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        key: "3",
        label: (
          <div onClick={() => state.openModal("artBoardModal")}>
            Back to Launch
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        type: "divider",
      },
      {
        key: "4",
        label: (
          <div
            onClick={() =>
              logout()
                .then(() => navigate(logoutLink))
                .catch(console.log)
            }
          >
            Log Out
          </div>
        ),
      },
    ].filter(it => !it.hidden);

  return (
    <>
      {state.personalInfo ? (
        <MenuActions items={getItems()}>
          <Space style={{ cursor: "pointer" }}>
            <Avatar
              size={35}
              style={{
                backgroundColor: token.sidebarAvatarBackground,
                color: token.sidebarAvatarColor,
              }}
            >
              <b style={{ fontSize: 18 }}>
                {
                  (state.personalInfo?.firstName ??
                    state.personalInfo?.lastName ??
                    "")[0]
                }
              </b>
            </Avatar>
            {!token.sidebarAvatarLabelHidden && (
              <span style={{ color: token.sidebarAvatarLabelColor }}>
                {state.personalInfo?.firstName}
                <br />
                {state.personalInfo?.lastName}
              </span>
            )}
          </Space>
        </MenuActions>
      ) : (
        <Avatar
          size={35}
          icon={<UserOutlined />}
          style={{
            backgroundColor: token.colorGrey1,
            cursor: "wait",
          }}
        />
      )}
    </>
  );
};

export default MenuProfile;
