import { Button, Modal, Row } from "antd";

import Close from "../../../../../../../../icons/Close";

const RestoreGoalDialog = ({ open, onCancel, onConfirm }) => (
  <Modal
    closeIcon={<Close />}
    footer={
      <Row justify={"space-between"}>
        <Button
          onClick={onConfirm}
          size={"small"}
          shape={"round"}
          type={"primary"}
        >
          Confirm
        </Button>
        <Button onClick={onCancel} shape={"round"} size={"small"} type={"text"}>
          Cancel
        </Button>
      </Row>
    }
    onCancel={onCancel}
    open={open}
    title={"Restore goal"}
    width={450}
  >
    <p style={{ margin: "50px 0" }}>
      Please confirm you would like to restore this goal
    </p>
  </Modal>
);

export default RestoreGoalDialog;
