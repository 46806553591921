import { Flex } from "antd";
import ChartPersonality from "../../../page/PageProposalDashboard/components/ChartPersonality";
import Header from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { LIST_PERSPECTIVES } from "../../../constant";

const ReportPagePersonalityUser = ({ personaName, perspectiveData }) => (
  <StylesContainer id={"reportPagePersonalityUser"}>
    <div className={"page-container"}>
      <Header personaName={personaName} />

      <main>
        <Flex vertical>
          <h2>{personaName}</h2>
          <h4>
            {
              perspectiveData?.calculation?.personalityList?.find(
                it => it.type === perspectiveData?.selectedPersonality
              )?.value
            }
            {"% "}
            Match {perspectiveData?.selectedPersonality?.toUpperCase()}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: LIST_PERSPECTIVES.find(
                it => it.name === perspectiveData?.selectedPersonality
              )?.description,
            }}
          />
        </Flex>
        <Flex className={"legend-container"} gap={24}>
          <Flex vertical>
            <span>{perspectiveData?.selectedPersonality}</span>
            <b>
              {perspectiveData?.calculation?.personalityList?.find(
                it => it.type === perspectiveData?.selectedPersonality
              )?.value + "% Match"}
            </b>
          </Flex>
          <div className={"marker"} />
        </Flex>
      </main>

      <Flex align={"center"} className={"chart-container"} justify={"center"}>
        <ChartPersonality height={800} hideLegend hideSpouse width={1000} />
      </Flex>
    </div>
  </StylesContainer>
);

export default ReportPagePersonalityUser;
