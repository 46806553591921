import { Button, Flex, Row, Typography } from "antd";
import CardProduct from "../../../../molecules/CardProduct";

import { ReactComponent as Gear } from "../../../../icons/gear.svg";

const RiversharesExplore = ({
  comparedProducts,
  handleCardClick,
  openModal,
  productActions,
  productsList,
  setCompareMode,
}) =>
  productsList?.length && (
    <Flex vertical>
      <Flex
        align={"center"}
        justify={"space-between"}
        style={{
          marginBottom: 10,
          paddingBottom: 8,
        }}
      >
        <Flex
          className={"pointer"}
          gap={8}
          onClick={() => openModal("defaultProductAssumptionsModal")}
        >
          <Gear />
          <Typography.Text>Default Product Assumptions</Typography.Text>
        </Flex>
        <Button
          disabled={!comparedProducts?.length}
          size={"small"}
          shape={"round"}
          type={"primary"}
          onClick={() => setCompareMode(true)}
        >
          Compare Mode ({comparedProducts?.length} of 3)
        </Button>
      </Flex>
      <Row style={{ gap: 20 }}>
        {productsList?.map(product => (
          <CardProduct
            key={product._id}
            product={product}
            actions={productActions(product)}
            handleCardClick={handleCardClick}
            labels={[product.riskTolerance, product.allocationType]}
          />
        ))}
      </Row>
    </Flex>
  );

export default RiversharesExplore;
