import { useContext } from "react";
import State from "../../../../../../context";
import { Flex } from "antd";
import ReportHeader from "../../../../pdf/component/ReportHeader";
import ReportPageCover from "../../../../pdf/page/ReportPageCover";
import ReportPageIntroduction from "../../../../pdf/page/ReportPageIntroduction";
import ReportPageIpq1 from "../../../../pdf/page/ReportPageIpq1";
import ReportPageIpq2 from "../../../../pdf/page/ReportPageIpq2";
import ReportPageMilestones from "../../../../pdf/page/ReportPageMilestones";
import ReportPagePersonalityIntro from "../../../../pdf/page/ReportPagePersonalityIntro";
import ReportPagePersonalityUser from "../../../../pdf/page/ReportPagePersonalityUser";
import ReportPagePersonalitySpouse from "../../../../pdf/page/ReportPagePersonalitySpouse";
import ReportPagePersonalityCouple from "../../../../pdf/page/ReportPagePersonalityCouple";
import ReportPagePersonalityCommonBlind from "../../../../pdf/page/ReportPagePersonalityCommonBlind";
import ReportPagePersonalityUnique from "../../../../pdf/page/ReportPagePersonalityUnique";
import ReportPagePriorities from "../../../../pdf/page/ReportPagePriorities";
import ReportPageProposalDisclosure from "../../../../pdf/page/ReportPageProposalDisclosure";
import ReportPageProposalFactSheet from "../../../../pdf/page/ReportPageProposalFactSheet";
import ReportPageProposalGrowth from "../../../../pdf/page/ReportPageProposalGrowth";
import ReportPageProposalHowToGet from "../../../../pdf/page/ReportPageProposalHowToGet";
import ReportPageProposalIncome from "../../../../pdf/page/ReportPageProposalIncome";

import { getCoupleCalculations } from "../../../../page/PageProposalDashboard/components/SectionPerspectiveCoupleResults";

const PdfContentHolder = () => {
  const [state] = useContext(State);

  const getCoupleName = () => {
    const guideInitialData = state.getPreferenceValue("guideInitialData");

    if (guideInitialData?.isMarried) {
      return `${state.personalInfo?.firstName} & ${guideInitialData.spouseFirstName} ${state.personalInfo?.lastName}`;
    } else {
      return getUserName();
    }
  };

  const getSpouseName = () =>
    `${state.getPreferenceValue("guideInitialData")?.spouseFirstName} ${state.getPreferenceValue("guideInitialData")?.spouseLastName}`;

  const getUserName = () =>
    `${state.personalInfo?.firstName} ${state.personalInfo?.lastName}`;

  const getSavedProposalFinancialProductName = () => {
    const productMap = state.getPreferenceValue("productMap");

    if (!productMap) return;

    const firstSavedProposalHistory = productMap[Object.keys(productMap)[0]];

    return firstSavedProposalHistory[firstSavedProposalHistory.length - 1]
      .productName;
  };

  return (
    <Flex gap={32} vertical>
      <ReportPageCover
        managerName={`${
          state.userManagerData?.personalInfo?.firstName ?? ""
        } ${state.userManagerData?.personalInfo?.lastName ?? ""}`}
        managerPhone={state.userManagerData?.personalInfo?.phoneNumber}
        personaName={getCoupleName()}
      />
      <ReportPageIntroduction
        managerName={`${
          state.userManagerData?.personalInfo?.firstName ?? ""
        } ${state.userManagerData?.personalInfo?.lastName ?? ""}`}
        managerPhone={state.userManagerData?.personalInfo?.phoneNumber}
        managerEmail={state.userManagerData?._id}
        personaName={getCoupleName()}
      />
      <ReportPagePersonalityIntro personaName={getCoupleName()} />
      <ReportPageProposalGrowth personaName={getCoupleName()} />
      <ReportPageProposalIncome personaName={getCoupleName()} />
      <ReportPageProposalHowToGet personaName={getCoupleName()} />
      <ReportPagePersonalityUser
        personaName={getUserName()}
        perspectiveData={state.getPreferenceValue("perspectiveMy")}
      />
      <ReportPagePersonalitySpouse
        personaName={getSpouseName()}
        perspectiveData={state.getPreferenceValue("perspectiveSpouse")}
      />
      <ReportPagePersonalityCouple
        personaName={getCoupleName()}
        userName={getUserName()}
        spouseName={getSpouseName()}
      />
      <ReportPagePersonalityCommonBlind
        coupleCalculations={getCoupleCalculations({
          perspectiveMy: state.getPreferenceValue("perspectiveMy"),
          perspectiveSpouse: state.getPreferenceValue("perspectiveSpouse"),
        })}
        personaName={getCoupleName()}
      />
      <ReportPagePersonalityUnique
        coupleCalculations={getCoupleCalculations({
          perspectiveMy: state.getPreferenceValue("perspectiveMy"),
          perspectiveSpouse: state.getPreferenceValue("perspectiveSpouse"),
        })}
        personaName={getCoupleName()}
        selectedPerspectiveUser={
          state.getPreferenceValue("perspectiveMy")?.selectedPersonality
        }
        selectedPerspectiveSpouse={
          state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality
        }
        userFirstName={state.personalInfo?.firstName}
        spouseFirstName={
          state.getPreferenceValue("guideInitialData")?.spouseFirstName
        }
      />
      <ReportPagePriorities
        cards={state.getPreferenceValue("prioritiesFamily")?.commonPicks}
        personaName={getCoupleName()}
        userLastName={state.personalInfo?.lastName}
      />
      <ReportPageMilestones
        milestonesQuestions={state.getPreferenceValue("milestonesQuestions")}
        milestonesNote={state.getPreferenceValue("milestonesNote")}
        personaName={getCoupleName()}
      />
      <ReportPageIpq1
        prospectObjective={state.getPreferenceValue("prospectObjective")}
      >
        <ReportHeader personaName={getCoupleName()} />
      </ReportPageIpq1>
      <ReportPageIpq2
        prospectObjective={state.getPreferenceValue("prospectObjective")}
      >
        <ReportHeader personaName={getCoupleName()} />
      </ReportPageIpq2>
      <ReportPageProposalFactSheet
        financialProductName={getSavedProposalFinancialProductName()}
      />
      <ReportPageProposalDisclosure
        financialProductName={getSavedProposalFinancialProductName()}
      />
    </Flex>
  );
};

export default PdfContentHolder;
