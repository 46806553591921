import { ConfigProvider } from "antd";
import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import locale from "antd/lib/locale/en_GB";

import {
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
  isPortalNext,
  isPortalSuperAdmin,
  isProposalPortal,
} from "../utils/helpers/specialized";
import astorManagerTheme from "../ecosystems/astor/themes/manager";
import astorUserTheme from "../ecosystems/astor/themes/user";
import bluepointManagerTheme from "../ecosystems/bluepoint/themes/manager";
import bluepointUserTheme from "../ecosystems/bluepoint/themes/user";
import goalsTheme from "../ecosystems/goals/theme";
import levelManagerTheme from "../ecosystems/level/theme/manager";
import levelProposalTheme from "../ecosystems/level/theme/proposal";
import nextDefaultTheme from "../ecosystems/next/theme";
import oneascentManagerTheme from "../ecosystems/oneascent/theme/manager";
import oneascentUserTheme from "../ecosystems/oneascent/theme/user";
import proposalTheme from "../ecosystems/proposal/theme";
import riversharesManagerTheme from "../ecosystems/rivershares/themes/manager";
import riversharesUserTheme from "../ecosystems/rivershares/themes/user";
import superAdminTheme from "../ecosystems/super_admin/theme";

const UI = ({ children, themeConfig, state }) => {
  const organization = state?.orgName ?? state?.organization?.name;

  const getThemeConfig = () => {
    if (isOrganizationBluepoint(organization)) {
      return state?.isManager ? bluepointManagerTheme : bluepointUserTheme;
    } else if (isOrganizationOneascent(organization)) {
      return state?.isManager ? oneascentManagerTheme : oneascentUserTheme;
    } else if (isOrganizationRivershares(organization)) {
      return state?.isManager ? riversharesManagerTheme : riversharesUserTheme;
    } else if (isOrganizationAstor(organization)) {
      return state?.isManager ? astorManagerTheme : astorUserTheme;
    } else if (isPortalNext()) {
      return nextDefaultTheme;
    } else if (isPortalSuperAdmin()) {
      return superAdminTheme;
    } else if (state?.isManager) {
      return levelManagerTheme;
    } else if (isProposalPortal()) {
      return isOrganizationLevel(organization)
        ? levelProposalTheme
        : proposalTheme;
    } else {
      return goalsTheme;
    }
  };

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          ...(themeConfig ?? getThemeConfig()),
        },
      }}
      locale={locale}
    >
      <StyleProvider
        hashPriority={"high"}
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        {children}
      </StyleProvider>
    </ConfigProvider>
  );
};

export default UI;
