import { lazy } from "react";

const PageGuide = lazy(() => import("../pages/PageGuide"));
const PageHaloDashboard = lazy(() => import("../../halo"));
const PageLogin = lazy(() => import("../pages/PageLogin"));
const PageNotFound = lazy(() => import("../../../pages/PageNotFound"));
const PageSuperAdminWelcome = lazy(
  () => import("../../super_admin/pages/PageWelcome")
);
const PageSymetra = lazy(() => import("../../symetra"));
const PageWelcomeAstor = lazy(() => import("../../astor/pages/PageWelcome"));
const PageWelcomeDefault = lazy(() => import("../pages/PageWelcome"));
const PageWelcomeHalo = lazy(() => import("../../halo/pages/PageWelcome"));
const PageWelcomeLevel = lazy(() => import("../../level/pages/PageWelcome"));
const PageWelcomeMmi = lazy(() => import("../../mmi/pages/PageWelcome"));
const PageWelcomeOneascent = lazy(
  () => import("../../oneascent/page/PageWelcome")
);
const PagePublicQuizPerspectives = lazy(
  () => import("../../oneascent/page/PagePublicQuizPerspectives")
);
const PagePublicQuizPriorities = lazy(
  () => import("../../oneascent/page/PagePublicQuizPriorities")
);
const PagePublicQuizMilestones = lazy(
  () => import("../../oneascent/page/PagePublicQuizMilestones")
);

export default {
  guide: PageGuide,
  halo: PageWelcomeHalo,
  "halo/dashboard": PageHaloDashboard,
  login: PageLogin,
  "login-astor": PageWelcomeAstor,
  "login-mmi": PageWelcomeMmi,
  "login-level": PageWelcomeLevel,
  "questionnaire-milestones": PagePublicQuizMilestones,
  "questionnaire-perspectives": PagePublicQuizPerspectives,
  "questionnaire-priorities": PagePublicQuizPriorities,
  "login-oneascent": PageWelcomeOneascent,
  "login-super-admin": PageSuperAdminWelcome,
  symetra: PageSymetra,
  "": PageWelcomeDefault,
  "*": PageNotFound,
};
