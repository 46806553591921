import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, Input, theme } from "antd";
import MenuActions from "../../../../../../atoms/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecules/ModalArchiveManagedUser";
import ModalConvertClient from "../ModalConvertClient";

import StyledTable from "./styles";

import {
  archiveLead,
  openProposal,
} from "../../../../../../utils/requests/manager";
import {
  getManagedUsers,
  handleCreateProposal,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
} from "../../../../../../utils/helpers/specialized";
import { getCurrencyValue } from "../../../../../../utils/helpers/general";

const getColumns = ({
  state,
  handleActionArchiveClientActionClick,
  handleActionConvertClient,
  handleActionEditClient,
  handleActionNewIpq,
  handleActionViewIpq,
  managerType,
  searchedText,
  token,
}) =>
  [
    {
      dataIndex: "clientName",
      filteredValue: [searchedText],
      key: "clientName",
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (name, restValues) =>
        restValues.proposalSaved ||
        isOrganizationOneascent(state.organization?.name) ? (
          <b>
            <a
              onClick={() =>
                openProposal({
                  userEmail: restValues.email,
                  organization: state.organization?.name,
                })
              }
            >
              {name}
            </a>
          </b>
        ) : (
          <b>{name}</b>
        ),
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      title: "Client Name",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      align: "right",
      dataIndex: "assets",
      key: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "left",
      hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
      key: "ipq",
      render: (_, allValues) =>
        allValues.prospectObjective && (
          <div
            onClick={() => handleActionViewIpq(allValues.email)}
            style={{
              color: token.colorSecondaryButton,
              cursor: "pointer",
            }}
          >
            View
          </div>
        ),
      title: "IPQ",
    },
    {
      align: "right",
      dataIndex: "updateDateTime",
      key: "updateDateTime",
      render: (value, restValues) =>
        restValues.proposalSaved ? (
          dayjs(value).format("MM/DD/YYYY")
        ) : (
          <Flex justify={"end"}>
            <Button
              shape="round"
              size="small"
              style={{
                borderColor: token.colorSecondaryButton,
                color: token.colorSecondaryButton,
              }}
              onClick={() => handleCreateProposal(restValues.email, state)}
              disabled={isAdminView()}
            >
              Create Proposal
            </Button>
          </Flex>
        ),
      sorter: (a, b) => dayjs(a.updateDateTime) - dayjs(b.updateDateTime),
      title: "Last Accessed",
    },
    {
      className: "actions-menu-table-cell",
      dataIndex: "actionsMenu",
      key: "actionsMenu",
      render: (value, restValues) => (
        <MenuActions
          items={getItems({
            email: restValues.email,
            handleActionArchiveClientActionClick,
            handleActionConvertClient,
            handleActionEditClient,
            handleActionNewIpq,
            handleActionViewIpq,
            managerType,
            organization: state.organization?.name,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getItems = ({
  email,
  handleActionConvertClient,
  handleActionEditClient,
  handleActionNewIpq,
  handleActionViewIpq,
  handleActionArchiveClientActionClick,
  managerType,
  organization,
}) =>
  [
    {
      key: "editClient",
      label: (
        <div onClick={() => handleActionEditClient(email)}>Edit Client</div>
      ),
    },
    {
      key: "convertClient",
      label: (
        <div
          onClick={() => {
            handleActionConvertClient(email);
          }}
        >
          {isOrganizationOneascent(organization)
            ? "Mark as Prospect"
            : "Move to Lead"}
        </div>
      ),
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      key: "newIpq",
      label: <div onClick={() => handleActionNewIpq(email)}>New IPQ</div>,
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      key: "viewIpq",
      label: <div onClick={() => handleActionViewIpq(email)}>View IPQ</div>,
    },
    {
      type: "divider",
    },
    {
      key: "archiveClient",
      label: (
        <div
          style={{ color: "red" }}
          onClick={() => handleActionArchiveClientActionClick(email)}
        >
          Archive
        </div>
      ),
    },
  ].filter(it => !it.hidden);

const TableLevelAdvisorClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);

  const handleActionEditClient = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalAddManagedUser", true);
  };

  const handleActionNewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("newIpqMode", true);
    state.setKeyValue("openModalProposalGuide", true);
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleActionConvertClient = email => {
    state.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleActionArchiveClient = () => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleActionArchiveClientActionClick = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const getTableData = () =>
    getManagedUsers(state)?.map(it => ({
      actionsMenu: { email: it.email },
      assets: it.valueMap?.productMap
        ? Object.keys(it.valueMap.productMap)
            .map(
              key =>
                it.valueMap.productMap[key][
                  it.valueMap.productMap[key].length - 1
                ].investmentAmount
            )
            .reduce((acc, value) => acc + value, 0)
        : (it.proposedAmount ?? 0),
      clientName: it.lastName + ", " + it.firstName,
      email: it.email,
      key: it.email,
      prospectObjective: it.valueMap?.prospectObjective,
      proposalSaved: it.proposalSavedDate ?? it.valueMap?.productMap,
      updateDateTime: it.updateDateTime?.$date,
    }));

  return (
    <>
      <Input
        style={{
          maxWidth: 290,
          height: 37,
          marginBottom: 16,
          borderRadius: 25,
        }}
        placeholder={"Search Clients..."}
        onChange={event => setSearchedText(event.target.value)}
      />
      <StyledTable
        dataSource={getTableData()}
        columns={getColumns({
          state,
          handleActionArchiveClientActionClick,
          handleActionConvertClient,
          handleActionEditClient,
          handleActionNewIpq,
          handleActionViewIpq,
          managerType: state.onboardingQuestionnaireType,
          searchedText,
          token,
        })}
        pagination={false}
        loading={state.loading}
      />

      <ModalArchiveManagedUser
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleActionArchiveClient}
        open={state.archiveManagedUserModal}
      />
      <ModalConvertClient
        email={state.activeEmail}
        handleClose={() => setIsConvertModalOpened(false)}
        open={isConvertModalOpened}
      />
    </>
  );
};

export default TableLevelAdvisorClients;
