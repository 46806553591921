import { Button, Flex, Modal, theme } from "antd";

import { isAdminView } from "../../../../utils/helpers/specialized";

import Close from "../../../../icons/Close";

const ModalConvertIntoLead = ({ email, onCancel, onSuccess, open }) => {
  const { token } = theme.useToken();

  return (
    <Modal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            type={"primary"}
            shape={"round"}
            onClick={onSuccess}
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            disabled={isAdminView()}
          >
            Confirm
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={"Move to Lead"}
    >
      <div style={{ textAlign: "center", padding: 40 }}>
        <p>
          This will move &quot;<b>{email}</b>&quot; to a lead.
        </p>
        <p>To see all leads, click on the Leads tab.</p>
      </div>
    </Modal>
  );
};

export default ModalConvertIntoLead;
