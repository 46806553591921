import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, InputNumber, Select, Table } from "antd";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helpers/general";

import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const selectOptions = [
  { value: "Brokerage Account", label: "Brokerage Account" },
  { value: "Mutual Funds", label: "Mutual Funds" },
  { value: "IRA-Roth", label: "IRA-Roth" },
  { value: "IRA-Traditional/SEP", label: "IRA-Traditional/SEP" },
  { value: "401k/403b/TSP", label: "401k/403b/TSP" },
  { value: "529 Plan", label: "529 Plan" },
  { value: "Savings/Checking", label: "Savings/Checking" },
  { value: "Home Equity", label: "Home Equity" },
  { value: "Health Savings Account", label: "Health Savings Account" },
  { value: "Other Account", label: "Other Account" },
  { value: "401k-Roth", label: "401k-Roth" },
  { value: "457b", label: "457b" },
  { value: "Cryptocurrency Account", label: "Cryptocurrency Account" },
];

const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form id={"editableAccountRow"} form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  selectOptions,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      dataIndex === "accountType" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select ref={inputRef} onBlur={save}>
            {selectOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            formatter={dataIndex === "startingBalance" && getCurrencyValue}
            parser={dataIndex === "startingBalance" && cleanCurrencyValue}
            onClick={selectValue}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TableAccount = ({ newUserData, handleUserDataChange }) => {
  const [count, setCount] = useState(2);

  const handleDelete = key => {
    const newData = newUserData.accounts.filter(item => item.key !== key);
    handleUserDataChange("accounts", newData);
  };

  const defaultColumns = [
    {
      title: "Account Type",
      dataIndex: "accountType",
      width: "30%",
      editable: true,
    },
    {
      title: "Starting Balance",
      dataIndex: "startingBalance",
      editable: true,
    },
    {
      title: "Start Age",
      dataIndex: "startAge",
      editable: true,
    },
    {
      title: "End Age",
      dataIndex: "endAge",
      editable: true,
    },
    {
      title: "Growth",
      dataIndex: "growth",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        newUserData.accounts.length >= 1 ? (
          <CloseOutlined onClick={() => handleDelete(record.key)} />
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      accountType: "Other Account",
      startingBalance: "0",
      startAge: "0",
      endAge: "0",
      growth: "0",
    };
    handleUserDataChange("accounts", [...newUserData.accounts, newData]);
    setCount(count + 1);
  };

  const handleSave = row => {
    const newData = [...newUserData.accounts];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    handleUserDataChange("accounts", newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        selectOptions,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={newUserData.accounts}
        columns={columns}
        pagination={false}
        size="small"
      />
      <Button type="text" size="small" shape="round" onClick={handleAdd}>
        + Add Account
      </Button>
    </div>
  );
};

export default TableAccount;
