import styled from "styled-components";
import { Button, Flex, Image, theme } from "antd";

import { isLockedByAdminView } from "../../../../../../utils/helpers/specialized";

import CloudsOutlined from "../../../../../../icons/CloudsOutlined";

const Container = styled(Flex)`
  background: #fff;
  padding: 24px;
  border-radius: 12px;

  .title {
    font-size: 22px;
    color: #1b5568;
    margin: 0;
  }

  .description {
    line-height: 20px;
    font-size: 14px;
    color: #1c5568;
    margin: 0;
  }
`;

const SectionIntroducingAdvice = ({
  handleGetAdviceButtonClick,
  isMobile,
  managerAccess,
}) => {
  const { token } = theme.useToken();

  return (
    <Container gap={14} vertical>
      <h3
        className={"title"}
        dangerouslySetInnerHTML={{
          __html: token.pageDashWantToTalkTitleContent,
        }}
      />
      <Flex align={"center"} gap={28}>
        <Flex gap={10} vertical>
          <p className={"description"}>
            You can click the “Get Advice” button any time to get in touch with
            a financial professional.
          </p>
          <p className={"description"}>
            <b
              dangerouslySetInnerHTML={{
                __html: token.pageDashWantToTalkLimitedContent,
              }}
            />
          </p>
        </Flex>
        <div>
          <Image
            preview={false}
            src={token.pageDashWantToTalkImageLink}
            width={130}
          />
        </div>
      </Flex>
      <Button
        disabled={isLockedByAdminView({ managerAccess })}
        onClick={handleGetAdviceButtonClick}
        shape={"round"}
        size={isMobile && "large"}
        style={{
          width: isMobile ? "100%" : 150,
          background: token.pageDashGetAdviceButtonBackground,
          color: token.pageDashGetAdviceButtonColor,
        }}
        type={"text"}
      >
        <Flex align={"center"} gap={12} justify={"center"}>
          <CloudsOutlined />
          <span style={{ fontSize: 16 }}>Get Advice</span>
        </Flex>
      </Button>
    </Container>
  );
};

export default SectionIntroducingAdvice;
