import { Flex } from "antd";
import Header from "../../component/ReportHeader";

import StylesContainer from "./styles";

const ReportPageProposalGrowth = ({ personaName }) => (
  <StylesContainer id={"reportPageProposalGrowth"}>
    <Flex className={"page-container"} gap={29} vertical>
      <Header personaName={personaName} />
      <h2>ReportPageProposalGrowth</h2>
    </Flex>
  </StylesContainer>
);

export default ReportPageProposalGrowth;
