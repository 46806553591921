import PageAdminAdvisors from "../../../pages/PageAdminAdvisors";
import PageAdminClients from "../../../pages/PageAdminClients";
import PageAdminProposal from "../../../pages/PageAdminProposal";
import PageMarketing from "../../../pages/PageMarketing";
import PageProducts from "../../../pages/PageProducts";

import Proposal from "../../../icons/Proposal";
import BoxOpen from "../../../icons/BoxOpen";
import DocumentPage from "../../../icons/DocumentPage";
import User from "../../../icons/User";
import Users from "../../../icons/Users";

export default [
  {
    path: "/oneascent-admin-portal/advisors",
    Component: PageAdminAdvisors,
    Icon: User,
    text: "Advisors",
  },
  {
    path: "/oneascent-admin-portal/products",
    Component: PageProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/oneascent-admin-portal/proposal",
    Component: PageAdminProposal,
    Icon: Proposal,
    text: "Proposal",
  },
  {
    path: "/oneascent-admin-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
  {
    path: "/oneascent-admin-portal/clients",
    Component: PageAdminClients,
    Icon: Users,
    text: "Clients",
  },
];
