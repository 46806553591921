import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import State from "../../../../../../context";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  theme,
} from "antd";
import ModalConfirmRiskChange from "../../../../../../organisms/ModalProposalGuideManager/components/ModalConfirmRiskChange";

import StylesContainer from "./styles";

import {
  calculateFinancialRiskToleranceMatrix,
  financialRiskToleranceCalculationData,
} from "../../constants";

import { updateProductObjective } from "../../../../../../utils/requests/regularApp";

const SELECT_OPTIONS = [
  {
    value: "preservation",
    label: "Preservation",
  },
  {
    value: "conservative",
    label: "Conservative",
  },
  {
    value: "moderate",
    label: "Moderate",
  },
  {
    value: "growth",
    label: "Growth",
  },
  {
    value: "equity",
    label: "Equity",
  },
];

const getChartCalculations = value => {
  if (value < 10) {
    return [5, "preservation"];
  } else if (value < 20) {
    return [15, "conservative"];
  } else if (value < 30) {
    return [25, "moderate"];
  } else if (value < 40) {
    return [35, "growth"];
  } else {
    return [45, "equity"];
  }
};

const SectionRiskSelection = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => {
  const [state] = useContext(State);
  const [formNote] = Form.useForm();
  const [formRiskAccounts] = Form.useForm();
  const { token } = theme.useToken();
  const [sliderValue, setSliderValue] = useState();
  const [
    sliderValueBasedOnRecommendationCalc,
    setSliderValueBasedOnRecommendationCalc,
  ] = useState();
  const [riskToleranceRecommendationName, setRiskToleranceRecommendationName] =
    useState("");
  const [openedModalConfirmation, setOpenedModalConfirmation] = useState(false);
  const [showAddNoteSection, setShowAddNoteSection] = useState(false);

  useEffect(() => {
    const calculatedRiskTolerance =
      calculateFinancialRiskToleranceRecommendation();

    updateProposalData("calculatedRiskTolerance", calculatedRiskTolerance);
    formRiskAccounts.setFieldsValue(proposalData?.listAccounts);
  }, []);

  useEffect(() => {
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
  }, [riskToleranceRecommendationName]);

  useEffect(() => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
  }, [sliderValueBasedOnRecommendationCalc]);

  const calculateFinancialRiskToleranceRecommendation = () => {
    const currentAgeRange =
      financialRiskToleranceCalculationData.currentAgeRange[
        proposalData.currentAgeRange
      ];
    const beginWithdrawal =
      financialRiskToleranceCalculationData.beginWithdrawal[
        proposalData.beginWithdrawal
      ];
    const incomeExpectations =
      financialRiskToleranceCalculationData.incomeExpectations[
        proposalData.incomeExpectations
      ];

    const investmentKnowledge =
      financialRiskToleranceCalculationData.investmentKnowledge[
        proposalData.investmentKnowledge
      ];
    const decisionInfluence =
      financialRiskToleranceCalculationData.decisionInfluence[
        proposalData.decisionInfluence
      ];
    const volatilityTolerance =
      financialRiskToleranceCalculationData.volatilityTolerance[
        proposalData.volatilityTolerance
      ];
    const decliningMarketWithholdingPeriod =
      financialRiskToleranceCalculationData.decliningMarketWithholdingPeriod[
        proposalData.decliningMarketWithholdingPeriod
      ];

    const totalPart1 = currentAgeRange + beginWithdrawal + incomeExpectations;
    const totalPart2 =
      investmentKnowledge +
      decisionInfluence +
      volatilityTolerance +
      decliningMarketWithholdingPeriod;

    let matrixPositionY, matrixPositionX;

    if (totalPart1 <= 5) {
      matrixPositionY = 0;
    } else if (totalPart1 <= 10) {
      matrixPositionY = 1;
    } else if (totalPart1 <= 16) {
      matrixPositionY = 2;
    } else if (totalPart1 <= 21) {
      matrixPositionY = 3;
    } else if (totalPart1 <= 25) {
      matrixPositionY = 4;
    } else {
      matrixPositionY = 5;
    }

    if (totalPart2 <= 8) {
      matrixPositionX = 0;
    } else if (totalPart2 <= 11) {
      matrixPositionX = 1;
    } else if (totalPart2 <= 14) {
      matrixPositionX = 2;
    } else if (totalPart2 <= 17) {
      matrixPositionX = 3;
    } else if (totalPart2 <= 20) {
      matrixPositionX = 4;
    } else if (totalPart2 <= 23) {
      matrixPositionX = 5;
    } else if (totalPart2 <= 27) {
      matrixPositionX = 6;
    } else if (totalPart2 <= 31) {
      matrixPositionX = 7;
    } else if (totalPart2 <= 35) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 38) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 41) {
      matrixPositionX = 10;
    } else if (totalPart2 <= 44) {
      matrixPositionX = 11;
    } else if (totalPart2 <= 47) {
      matrixPositionX = 12;
    } else {
      matrixPositionX = 13;
    }

    const riskToleranceStrategyRecommendation =
      calculateFinancialRiskToleranceMatrix[matrixPositionY][matrixPositionX];

    switch (riskToleranceStrategyRecommendation) {
      case "P":
        setSliderValueBasedOnRecommendationCalc(5);
        setRiskToleranceRecommendationName("preservation");
        return "preservation";
      case "C":
        setSliderValueBasedOnRecommendationCalc(15);
        setRiskToleranceRecommendationName("conservative");
        return "conservative";
      case "M":
        setSliderValueBasedOnRecommendationCalc(25);
        setRiskToleranceRecommendationName("moderate");
        return "moderate";
      case "G":
        setSliderValueBasedOnRecommendationCalc(35);
        setRiskToleranceRecommendationName("growth");
        return "growth";
      case "E":
        setSliderValueBasedOnRecommendationCalc(45);
        setRiskToleranceRecommendationName("equity");
        return "equity";
    }
  };

  const handleFormValuesChange = useDebouncedCallback(
    (changedValues, allValues) => {
      updateProposalData("listAccounts", allValues);
    },
    500
  );

  const handleNoteAdd = () => {
    setShowAddNoteSection(true);
    formNote.setFieldValue("note", proposalData.note);
  };

  const handleNoteCancel = () => {
    setShowAddNoteSection(false);
  };

  const handleNoteSave = ({ note }) => {
    state.setKeyValue("loading", true);

    const activeUserData = state.managedUsers?.find(
      it => it.email === state.activeEmail
    );

    updateProductObjective({
      email: activeUserData.email,
      ...activeUserData.valueMap.prospectObjective,
      note,
    })
      .then(() => {
        state.setKeyValue("loading", false);
        state.showSuccess("Your note has been saved");
        updateProposalData("note", note);
      })
      .catch(error => {
        state.showError(error.response?.data);
        state.setKeyValue("loading", false);
      });
  };

  const handleRiskChangeConfirm = () => {
    const [roundedSliderValue, riskToleranceType] =
      getChartCalculations(sliderValue);
    setSliderValue(roundedSliderValue);
    updateProposalData("riskTolerance", riskToleranceType);
    setOpenedModalConfirmation(false);
  };

  const handleRiskChangeCancel = () => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
    setOpenedModalConfirmation(false);
  };

  const SectionNote = () =>
    showAddNoteSection ? (
      <Form
        form={formNote}
        id={"formNote"}
        onFinish={handleNoteSave}
        style={{ width: "100%" }}
      >
        <Flex gap={12} style={{ marginTop: 20, width: "100%" }} vertical>
          <Form.Item name={"note"}>
            <Input.TextArea style={{ height: 131, maxWidth: 542 }} />
          </Form.Item>
          <Flex gap={20}>
            <Button
              htmlType={"submit"}
              shape={"round"}
              size={"small"}
              style={{ background: "#192849", fontSize: 16, width: 106 }}
              type={"primary"}
            >
              Save note
            </Button>
            <Button
              shape={"round"}
              size={"small"}
              style={{ color: "#19284950", fontSize: 16 }}
              type={"text"}
              onClick={handleNoteCancel}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Form>
    ) : (
      <Button
        onClick={handleNoteAdd}
        shape={"round"}
        size={"small"}
        style={{ color: token.colorPrimary, marginTop: 20 }}
        type={"text"}
      >
        + Add a note
      </Button>
    );

  return (
    <StylesContainer gap={50} justify={"space-between"} wrap={"wrap"}>
      <Flex style={{ width: 400 }} vertical>
        <h3 className="title" style={{ margin: "0 0 10px" }}>
          Risk Selection
        </h3>
        <p className="description" style={{ margin: "0 0 52px" }}>
          Based on your answers, your calculated risk score is{" "}
          <b style={{ textTransform: "capitalize" }}>
            {proposalData.riskTolerance}.
          </b>
        </p>
        <Radio.Group
          onChange={e =>
            updateProposalData("acceptedRiskLevel", e.target.value)
          }
          value={proposalData.acceptedRiskLevel}
        >
          <Flex gap={20} vertical>
            <Radio value={"recommended"}>
              All accounts should be invested according to my measured risk
              level:{" "}
              <b style={{ textTransform: "capitalize" }}>
                {getChartCalculations(sliderValue)[1]}
              </b>
            </Radio>
            <Radio value={"custom"}>
              I wish to deviate from my measured risk level and request that my
              accounts be invested as indicated
            </Radio>
          </Flex>
        </Radio.Group>
        <ButtonsContainer />
      </Flex>
      <Flex align={"start"} style={{ width: 562 }} vertical>
        <Flex
          gap={24}
          style={{
            display:
              proposalData.acceptedRiskLevel === "custom" ? "flex" : "none",
          }}
          vertical
        >
          <p>
            Please adequately describe each account below, including the{" "}
            <b>last 4 digits of the account number</b> where appropriate. If you
            are to recommend alternative investments be incorporated into a
            given risk tolerance, please list the corresponding account(s), risk
            tolerance(s), and “with alternatives” by clicking “Add a note.”
          </p>
          <Form
            form={formRiskAccounts}
            id={"formRiskAccounts"}
            layout={"vertical"}
            onValuesChange={handleFormValuesChange}
            style={{
              width: "100%",
            }}
          >
            <Flex gap={15}>
              <Flex style={{ width: "100%" }} vertical>
                <span className={"row-account-label"}>Account</span>
                <Form.Item name={"account__name__1"}>
                  <Input placeholder={"Name of account..."} size={"large"} />
                </Form.Item>
              </Flex>
              <Flex vertical>
                <span className={"row-account-label"}>Last four</span>
                <Form.Item name={"account__lastFour__1"}>
                  <InputNumber
                    controls={false}
                    formatter={value => value.slice(-4)}
                    min={0}
                  />
                </Form.Item>
              </Flex>
              <Flex vertical>
                <span className={"row-account-label"}>Risk tolerance</span>
                <Form.Item name={"account__riskType__1"}>
                  <Select options={SELECT_OPTIONS} size={"large"} />
                </Form.Item>
              </Flex>
            </Flex>
            <Flex gap={15}>
              <Form.Item name={"account__name__2"} style={{ width: "100%" }}>
                <Input placeholder={"Name of account..."} size={"large"} />
              </Form.Item>
              <Form.Item name={"account__lastFour__2"}>
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name={"account__riskType__2"}>
                <Select options={SELECT_OPTIONS} size={"large"} />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name={"account__name__3"} style={{ width: "100%" }}>
                <Input placeholder={"Name of account..."} size={"large"} />
              </Form.Item>
              <Form.Item name={"account__lastFour__3"}>
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name={"account__riskType__3"}>
                <Select options={SELECT_OPTIONS} size={"large"} />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name={"account__name__4"} style={{ width: "100%" }}>
                <Input placeholder={"Name of account..."} size={"large"} />
              </Form.Item>
              <Form.Item name={"account__lastFour__4"}>
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name={"account__riskType__4"}>
                <Select options={SELECT_OPTIONS} size={"large"} />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name={"account__name__5"} style={{ width: "100%" }}>
                <Input placeholder={"Name of account..."} size={"large"} />
              </Form.Item>
              <Form.Item name={"account__lastFour__5"}>
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name={"account__riskType__5"}>
                <Select options={SELECT_OPTIONS} size={"large"} />
              </Form.Item>
            </Flex>
          </Form>
        </Flex>
        <div
          style={{
            display:
              proposalData.acceptedRiskLevel !== "custom" ? "block" : "none",
            marginTop: 30,
            width: 524,
          }}
        >
          <Flex
            justify={"space-evenly"}
            style={{ color: token.colorNavy, marginBottom: 21 }}
          >
            <span
              className={`chart-label ${
                getChartCalculations(sliderValue)[1] === "preservation" &&
                "active"
              }`}
            >
              Preservation
            </span>
            <span
              className={`chart-label ${
                getChartCalculations(sliderValue)[1] === "conservative" &&
                "active"
              }`}
            >
              Conservative
            </span>
            <span
              className={`chart-label ${
                getChartCalculations(sliderValue)[1] === "moderate" && "active"
              }`}
            >
              Moderate
            </span>
            <span
              className={`chart-label ${
                getChartCalculations(sliderValue)[1] === "growth" && "active"
              }`}
            >
              Growth
            </span>
            <span
              className={`chart-label ${
                getChartCalculations(sliderValue)[1] === "equity" && "active"
              }`}
            >
              Equity
            </span>
          </Flex>
          <div className={"chart-part-container"}>
            <div
              style={{
                height: 116,
                background:
                  "conic-gradient(transparent 0deg 80deg, #5DCA83 82deg 260deg, transparent 262deg)",
                transform: `translateY(${50 - sliderValue}px) rotateX(${
                  (50 - sliderValue) * 1.5
                }deg)`,
              }}
            />
            <span className={"chart-y-axis-title top"}>Reward</span>
          </div>
          <Slider
            value={sliderValue}
            min={0}
            max={50}
            step={1}
            tooltip={{ open: false }}
            trackStyle={{
              height: 11,
              background: "#E7EBEE",
              borderRadius: 35,
              boxShadow: "inset 0 2px 6px #00000067",
              cursor: "default",
            }}
            railStyle={{
              height: 11,
              background: "#E7EBEE",
              borderRadius: 10,
              boxShadow: "inset 0 2px 6px #00000067",
              cursor: "default",
            }}
            onChange={value => setSliderValue(value)}
            onAfterChange={() => setOpenedModalConfirmation(true)}
          />
          <div className={"chart-part-container"}>
            <div
              style={{
                height: 100,
                background:
                  "conic-gradient(#D9D9D9 94deg, transparent 96deg 277deg, #D9D9D9 276deg)",
                boxShadow: "inset 0 10px 10px white",
                transform: `translateY(-${(50 - sliderValue) / 2}px) rotateX(${
                  (50 - sliderValue) * 0.75
                }deg)`,
              }}
            />
            <span className={"chart-y-axis-title"}>Risk</span>
          </div>
        </div>
        <SectionNote />
      </Flex>

      <ModalConfirmRiskChange
        onCancel={handleRiskChangeCancel}
        onSuccess={handleRiskChangeConfirm}
        open={openedModalConfirmation}
      />
    </StylesContainer>
  );
};

export default SectionRiskSelection;
