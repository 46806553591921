import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../../../context";
import { Button, Flex, theme } from "antd";
import CardGenerosity from "./component/CardGenerosity";
import CardInvestments from "./component/CardInvestments";
import CardMilestones from "./component/CardMilestones";
import CardPerspectives from "./component/CardPerspectives";
import CardPriorities from "./component/CardPriorities";
import ModalCreateReport from "../../../../component/ModalCreateReport";
import ModalPerspectives from "../ModalPerspectives";
import ModalPriorities from "../ModalPriorities";
import ModalProposalGuideUser from "../../../../component/ModalProposalGuideUser";
import ModalSendSurveyLink from "../ModalSendSurveyLink";
import ModalViewIpq from "../../../../component/ModalViewIpq";

import StylesContainer from "./styles";

import { getQuizLink, sendQuizLinkOnEmail } from "../../../../helper/request";
import { isButtonEnabledCreateReport } from "../../../../helper/feature";

import { ReactComponent as IconPdf } from "../../image/icon_pdf.svg";

const SectionWorkspace = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenLGMax });

  const guideInitialData = state.getPreferenceValue("guideInitialData");
  const isIpqUser =
    state.userManagerData?.onboardingQuestionnaireType === "IPQ";

  const getLinkValueMapKey = () => {
    if (state.surveyLinkFor === "milestones") return "milestonesQuestions";
    else if (state.surveyLinkFor.includes("perspective"))
      return state.perspectiveFor;
    else return state.prioritiesFor;
  };

  const getRoute = () => {
    if (state.surveyLinkFor === "milestones") return "questionnaire-milestones";
    else if (state.surveyLinkFor.includes("perspective"))
      return "questionnaire-perspectives";
    else return "questionnaire-priorities";
  };

  const handleCopyLink = () => {
    setLoading(true);

    getQuizLink({
      userId: state._id,
      userManagerId: state.userManagerData?._id,
      userManagerFullName: `${
        state.userManagerData?.personalInfo?.firstName ?? ""
      } ${state.userManagerData?.personalInfo?.lastName ?? ""}`,
      route: getRoute(),
      valueMapKey: getLinkValueMapKey(),
    })
      .then(link => {
        navigator?.clipboard?.writeText(link);
        state.showSuccess("Link copied");
      })
      .finally(() => setLoading(false));
  };

  const handlePerspectivesClose = () => {
    state.setKeyValue("openModalPerspectives", false);
    state.setKeyValue("perspectiveFor", null);
  };

  const handlePerspectivesComplete = perspectives => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.perspectiveFor, perspectives)
      .then(() => {
        state.showSuccess("Perspectives saved");
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handlePerspectivesViewResults = perspectiveType => {
    state.setKeyValue("viewPerspectiveIndividualResults", perspectiveType);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handlePrioritiesClose = () => {
    state.setKeyValue("openModalPriorities", false);
    state.setKeyValue("prioritiesFor", null);
  };

  const handlePrioritiesComplete = priorities => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.prioritiesFor, priorities)
      .then(() => {
        state.showSuccess("Priorities saved");
      })
      .catch(error => console.log(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleSurveyLinkCancel = () => {
    state.setKeyValue("openModalSurveyLink", false);
    state.setKeyValue("surveyLinkFor", null);
  };

  const handleSurveyLinkContinue = () => {
    state.setKeyValue("openModalSurveyLink", false);

    if (state.surveyLinkFor === "milestones") {
      state.setKeyValue("viewMilestonesQuestionnaire", true);
      state.setKeyValue("showButtonBackToDashboard", true);
    } else if (state.surveyLinkFor?.includes("perspective")) {
      state.setKeyValue("openModalPerspectives", true);
    } else {
      state.setKeyValue("openModalPriorities", true);
    }
  };

  const handleSurveyLinkSend = ({ email }) => {
    if (!email) {
      state.showWarning("Please, fill 'email' field before sending");
      return;
    }

    setLoading(true);

    sendQuizLinkOnEmail({
      emailDestination: email,
      userId: state._id,
      userManagerId: state.userManagerData?._id,
      userManagerFullName: `${
        state.userManagerData?.personalInfo?.firstName ?? ""
      } ${state.userManagerData?.personalInfo?.lastName ?? ""}`,
      route: `questionnaire-${
        state.perspectiveFor ? "perspectives" : "priorities"
      }`,
      valueMapKey: getLinkValueMapKey(),
    })
      .then(() => {
        state.showSuccess(`Email sent to: ${email}`);
      })
      .catch(error => {
        console.log(error);
        state.showError("Error occurred. Please, try later");
      })
      .finally(() => setLoading(false));
  };

  const getProposalData = () => {
    const productMap = state.getPreferenceValue("productMap");

    if (!productMap) return;

    const firstProposalHistoryList = productMap[Object.keys(productMap)[0]];

    return firstProposalHistoryList[firstProposalHistoryList.length - 1];
  };

  return (
    <StylesContainer gap={40} vertical>
      <Flex align={"end"} justify={"space-between"}>
        <Flex className={"header"} gap={11} vertical>
          <h3 className={"title"}>
            Welcome, {state.personalInfo?.firstName}
            {guideInitialData?.spouseFirstName
              ? ` and ${guideInitialData?.spouseFirstName}`
              : ""}
          </h3>
          <p className={"description"}>
            Align empowers your financial life to be clear, strategic, and
            generous through values-based planning, investing, and giving.{" "}
            <b>Plan</b> in the present. <b>Invest</b> in your future.{" "}
            <b>Give</b> freely and generously.
          </p>
        </Flex>
        {isIpqUser && (
          <Button
            disabled={!isButtonEnabledCreateReport()}
            icon={<IconPdf />}
            onClick={() => state.setKeyValue("openModalCreateReport", true)}
            shape={"round"}
            style={{ width: 150 }}
            type={"primary"}
          >
            Create Report
          </Button>
        )}
      </Flex>
      {isSmallScreen ? (
        <Flex gap={34} vertical>
          {isIpqUser && (
            <>
              <CardPerspectives
                handlePerspectivesViewResults={handlePerspectivesViewResults}
              />
              <CardPriorities />
              <CardMilestones />
              <CardGenerosity />
            </>
          )}
          <CardInvestments proposalData={getProposalData()} />
        </Flex>
      ) : (
        <Flex gap={34}>
          <Flex gap={34} vertical>
            {isIpqUser && (
              <>
                <CardPerspectives
                  handlePerspectivesViewResults={handlePerspectivesViewResults}
                />
                <CardMilestones />
                <CardGenerosity />
              </>
            )}
          </Flex>
          <Flex gap={34} vertical>
            {isIpqUser && <CardPriorities />}
            <CardInvestments proposalData={getProposalData()} />
          </Flex>
        </Flex>
      )}

      <ModalCreateReport
        onCancel={() => state.setKeyValue("openModalCreateReport", false)}
        open={state.openModalCreateReport}
      />
      <ModalPerspectives
        handleComplete={handlePerspectivesComplete}
        handleViewResults={handlePerspectivesViewResults}
        hideButtonViewResults={
          !state.getPreferenceValue("guideInitialData")?.isMarried
        }
        onCancel={handlePerspectivesClose}
        open={state.openModalPerspectives}
      />
      <ModalPriorities
        handleComplete={handlePrioritiesComplete}
        initialState={
          !state.reTakePrioritiesQuizMode &&
          state.getPreferenceValue(state.prioritiesFor)
        }
        onCancel={handlePrioritiesClose}
        open={state.openModalPriorities}
      />
      <ModalProposalGuideUser
        open={state.openModalProposalGuide}
        handleClose={() => state.setKeyValue("openModalProposalGuide", false)}
      />
      <ModalSendSurveyLink
        email={
          state.surveyLinkFor?.includes("Spouse")
            ? state.getPreferenceValue("guideInitialData")?.spouseEmail
            : state._id
        }
        handleCopyLink={handleCopyLink}
        loading={loading}
        open={state.openModalSurveyLink}
        onCancel={handleSurveyLinkCancel}
        onContinue={handleSurveyLinkContinue}
        onSend={handleSurveyLinkSend}
      />
      <ModalViewIpq
        activeUserData={{
          ...state.personalInfo,
          ...state.preferences,
        }}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default SectionWorkspace;
