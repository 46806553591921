import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import State from "../../../../context";
import { Empty, Layout } from "antd";
import Header from "../../components/Header";
import ModalDeleteGoal from "../../components/ModalDeleteGoal";
import ModalGoalSettings from "../../../../organisms/ModalGoalSettings";
import ModalMonthlyExpenses from "../../components/ModalMonthlyExpenses";
import ModalOtherGrowthTypes from "../../components/ModalOtherGrowthTypes";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import ModalUpdateProgress from "../../components/ModalUpdateProgress";
import SkeletonGoal from "./components/SkeletonGoal";
import WorkspaceGrowthTypeCompare from "../../components/WorkspaceGrowthTypeCompare";
import WorkspaceEmergencySavings from "./components/WorkspaceEmergencySavings";
import WorkspaceGoal from "./components/WorkspaceGoal";

import { DEFAULT_ADVISOR_FEE } from "../../../../utils/constants";

import {
  deleteGoal,
  getLiveAssessment,
  getLiveSimulation,
  updateGoal2progress,
} from "../../../../utils/requests/regularApp";
import { getPercentValue } from "../../../../utils/helpers/general";
import { getRecommendedProductId } from "../../../proposal/component/ViewProposalGoal/helpers";
import { getGoalIdByGoalName } from "../../../../utils/helpers/specialized";
import {
  getGoalsAppOrganization,
  getSavedProgressValuesByGoalId,
} from "../../helpers";

const PageGoal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const goalName = id.replaceAll("_", " ").replaceAll("%25", "%");
  const [state, setState] = useContext(State);
  const [goalData, setGoalData] = useState();
  const [loading, setLoading] = useState(false);
  const [calculationsData, setCalculationsData] = useState();
  const [simulationsData, setSimulationsData] = useState();
  const [progressValues, setProgressValues] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);
  const goalId = getGoalIdByGoalName(
    state.getPreferenceValue("productMap"),
    goalName
  );

  useEffect(() => {
    state.setKeyValue("compareProducts", []);
    state.setKeyValue("selectedProduct", null);
  }, []);

  useEffect(() => {
    if (goalData && id !== "Emergency_Savings") {
      updateTrajectoryData();
    } else {
      state.setKeyValue("loading", false);
      setLoading(false);
    }
  }, [goalData]);

  useEffect(() => {
    if (state.getPreferenceValue("productMap") && !goalData) {
      setGoalData(getGoalData(goalName));

      setProgressValues(
        getSavedProgressValuesByGoalId(goalId, state.productScenariosMap)
      );
    }
  }, [state, goalName]);

  useEffect(() => {
    if (goalData && !state.selectedProduct) {
      const recommendedProductId =
        state.selectedProduct ??
        goalData.recommendedProductId ??
        goalData.productId ??
        getRecommendedProductId({
          organization: "goals",
          productsList: state.productsList,
          proposalData: goalData,
        });

      if (recommendedProductId) {
        setState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [goalData, state]);

  const getGoalData = goalName => {
    let goalData = {};
    const level2Map = state.getPreferenceValue("level2Map");
    const productMap = state.getPreferenceValue("productMap");

    if (level2Map) {
      goalData = level2Map[goalName];
    }

    if (productMap) {
      goalData = Object.keys(productMap)
        ?.map(key => productMap[key][productMap[key].length - 1])
        ?.find(it => (it.goalName ?? it.productName) === goalName);
    }

    return goalData;
  };

  const areAllProgressDatesUnic = progressArray => {
    const seenDates = new Set();

    for (const item of progressArray) {
      const dateStr = dayjs(item.date.$date).format("MM/DD/YYYY");

      if (seenDates.has(dateStr)) {
        return false;
      }

      seenDates.add(dateStr);
    }

    return true;
  };

  const handleCardClick = cardId => {
    setState(lastState => ({
      ...lastState,
      productDetailsId: cardId,
    }));
    state.openModal("productDetailsModal");
  };

  const handleDeleteGoal = () => {
    state.setKeyValue("loading", true);

    deleteGoal(
      getGoalIdByGoalName(state.getPreferenceValue("productMap"), goalName)
    )
      .then(() => {
        state.setUserData().then(() => {
          state.setKeyValue("loading", false);
          state.showSuccess("Goal deleted!");
          navigate("/goals");
        });
      })
      .catch(e => {
        console.log(e);
        state.setKeyValue("loading", false);
      });
  };

  const saveGoalSettings = requestBody => {
    state.setKeyValue("loading", true);

    getLiveAssessment({
      objective: {
        ...requestBody,
      },
      organization: "goals",
      productId: requestBody.productId,
      saveAction: "PUT",
      saveId: goalId,
      activeGoal: goalId,
    })
      .then(() => {
        state.setUserData().then(data => {
          setGoalData(
            data.preferences.valueMap.productMap[goalId][
              data.preferences.valueMap.productMap[goalId].length - 1
            ]
          );

          state.closeModal("goalSettingsModal");
          state.showSuccess("Goal Settings updated!");
        });
      })
      .catch(console.log);
  };

  const saveProgress = (updatedProgressValues, successCallback) => {
    if (areAllProgressDatesUnic(updatedProgressValues)) {
      if (!updatedProgressValues.length) {
        state.showWarning("You need to fill data for first Progress Point");
        return;
      }

      state.closeModal("updateProgressModal");
      state.setKeyValue("loading", true);

      updateGoal2progress(goalId, updatedProgressValues)
        .then(() => {
          state.setUserData().then(userData =>
            getLiveAssessment({
              objective: goalData,
              organization: "goals",
              productId: goalData.productId,
              saveId: goalId,
              activeGoal: goalId,
            })
              .then(data => {
                state.showSuccess("Progress History updated!");
                setCalculationsData(data);
                setProgressValues(
                  getSavedProgressValuesByGoalId(
                    goalId,
                    userData.productScenariosMap
                  )
                );
                successCallback && successCallback();
              })
              .catch(error => state.showError(error.response?.data))
              .finally(() => state.setKeyValue("loading", false))
          );
        })
        .catch(error => {
          state.showError(error.response?.data);
          state.setKeyValue("loading", false);
        });
    } else {
      state.showWarning("You can't set two progress values on the same date!");
    }
  };

  const updateGoalProduct = productId => {
    setCompareMode(false);

    setState(oldState => ({
      ...oldState,
      compareProducts: [productId],
      selectedProduct: productId,
    }));
    saveGoalSettings({
      ...goalData,
      productId,
    });
  };

  const updateTrajectoryData = () => {
    setLoading(true);

    getLiveAssessment({
      activeGoal: goalId,
      objective: goalData,
      organization: getGoalsAppOrganization(state.organization?.name),
      productId: goalData?.productId,
      saveId: goalId,
    })
      .then(data => {
        setCalculationsData(data);
        getLiveSimulation({
          historicalAnalysis: true,
          montecarlo: true,
          objective: goalData,
          organization: getGoalsAppOrganization(state.organization?.name),
          productId: goalData?.productId,
        })
          .then(liveSimulations => {
            state.setUserData().then(() => {
              setSimulationsData(liveSimulations);
              setLoading(false);
              state.setKeyValue("loading", false);
            });
          })
          .catch(e => {
            console.log(e);
            setLoading(false);
          });
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const compareListCardAction = [
    {
      buttonActiveCopy: "Selected to Compare",
      buttonCopy: "Add to Compare",
      key: "addToCompare",
      onSelect: product => {
        setState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        state.closeModal("otherGrowthTypesModal");
      },
    },
  ];

  const defaultListCardAction = [
    {
      buttonActiveCopy: "Applied to goal",
      buttonCopy: "Apply to goal",
      key: "addToGoal",
      onSelect: product => {
        state.closeModal("otherGrowthTypesModal");
        const newProductData = state.productsList.find(
          it => it._id === product._id
        );

        if (newProductData) {
          setGoalData(lastState => ({
            ...lastState,
            riskTolerance: newProductData.riskTolerance,
          }));
        }

        updateGoalProduct(product._id);
      },
    },
  ];

  const handleMonthlyExpensesModalSave = values => {
    const totalValues = Object.values(values)?.reduce((acc, it) => acc + it, 0);

    saveGoalSettings({
      ...goalData,
      monthlyExpenses: totalValues,
      monthlyExpensesItems: values,
    });
    state.closeModal("monthlyExpensesModal");
  };

  return (
    <>
      <SkeletonGoal loading={state.loading}>
        <Header style={{ background: "#ffffff" }}>
          <b style={{ fontSize: 20, color: "#1B5568" }}>Goal</b>
        </Header>
        <Layout.Content style={{ background: "#ffffff" }}>
          {goalData ? (
            compareMode ? (
              <WorkspaceGrowthTypeCompare
                goalData={goalData}
                exitCompareMode={() => setCompareMode(false)}
                handleCardClick={handleCardClick}
                isCompareChartDirty={isCompareChartDirty}
                setIsCompareChartDirty={setIsCompareChartDirty}
                updateGoalProduct={updateGoalProduct}
              />
            ) : id === "Emergency_Savings" ? (
              <WorkspaceEmergencySavings
                goalData={goalData}
                handleCardClick={handleCardClick}
                saveGoalSettings={saveGoalSettings}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                state={state}
              />
            ) : (
              <WorkspaceGoal
                calculationsData={calculationsData}
                goalData={goalData}
                handleCardClick={handleCardClick}
                loading={loading}
                saveGoalSettings={saveGoalSettings}
                setCompareMode={setCompareMode}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                simulationsData={simulationsData}
                state={state}
              />
            )
          ) : (
            <Empty />
          )}
        </Layout.Content>
      </SkeletonGoal>

      <ModalDeleteGoal
        handleOk={handleDeleteGoal}
        handleCancel={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
      />
      <ModalGoalSettings
        handleClose={() => state.closeModal("goalSettingsModal")}
        handleUpdateGoal={saveGoalSettings}
        loading={state.loading}
        open={state.goalSettingsModal}
        organization={getGoalsAppOrganization(state.organization?.name)}
        proposalData={goalData}
      />
      <ModalMonthlyExpenses
        onCancel={() => state.closeModal("monthlyExpensesModal")}
        onSave={handleMonthlyExpensesModalSave}
        open={state.monthlyExpensesModal}
        values={goalData?.monthlyExpensesItems}
      />
      <ModalOtherGrowthTypes
        cardActions={
          compareMode ? compareListCardAction : defaultListCardAction
        }
        compareProducts={state.compareProducts}
        handleCardClick={handleCardClick}
        handleClose={() => state.closeModal("otherGrowthTypesModal")}
        open={state.otherGrowthTypesModal}
        productsList={state.productsList}
        selectedProduct={state.selectedProduct}
      />
      <ModalProductDetails
        handleClose={() => state.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={{}}
        open={state.productDetailsModal}
        organization={"goals"}
        product={{
          ...state.productsList.find(it => it._id === state.productDetailsId),
          ...goalData,
          advisorFee: getPercentValue(
            goalData?.advisorFee ?? DEFAULT_ADVISOR_FEE
          ),
          withdrawalLevel: getPercentValue(goalData?.withdrawalLevel ?? 0),
        }}
        productsList={state.productsList}
      />
      <ModalUpdateProgress
        goalName={goalName}
        progressValues={progressValues}
        saveProgress={saveProgress}
        setProgressValues={setProgressValues}
      />
    </>
  );
};

export default PageGoal;
