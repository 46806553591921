import dayjs from "dayjs";
import { getGoalIdByGoalName } from "../../../../../../../utils/helpers/specialized";
import { getCurrencyValue } from "../../../../../../../utils/helpers/general";

export const getEmergencySavingsCalculations = ({ goalData, state }) => {
  const goalId = getGoalIdByGoalName(
    state.getPreferenceValue("productMap"),
    goalData?.goalName
  );
  const [lastScenarioData] = state.productScenariosMap[goalId]?.slice(-1);
  const lastProgressData = lastScenarioData?.progress?.slice(-1)?.[0];
  const goalCreationTimestamp = state.productScenariosMap[goalId][0].timestamp;

  const investmentMonthQuantity = 12 * goalData?.investmentDuration;
  const monthlyExpenses = goalData?.monthlyExpenses;
  const targetAmount = investmentMonthQuantity * goalData?.monthlyExpenses;

  const getGoalFundedValue = () => {
    if (getShortfallValue() <= 0) {
      return 100;
    } else {
      return Math.round(
        ((targetAmount - getShortfallValue()) / targetAmount) * 100
      );
    }
  };

  const getProjectionSeries = () => {
    const monthlyContributions = goalData?.contributions / 12;

    const originalProjectionData = [];
    let originalProjectedAmount = goalData?.investmentAmount;
    let originalProjectionMonthQuantity = 0;

    do {
      originalProjectionData.push({
        y: (originalProjectedAmount += monthlyContributions),
        x: originalProjectionMonthQuantity++,
      });
    } while (originalProjectedAmount < targetAmount);

    const actualProjectionData = [];
    let actualProjectedAmount = lastProgressData?.actualTotal;
    let actualProjectionMonthQuantity = 0;
    const differenceInMonthsWithLastProgress = dayjs(
      lastProgressData?.date?.$date
    ).diff(lastScenarioData?.properties?.startDate, "month");

    do {
      actualProjectionData.push({
        y:
          actualProjectionMonthQuantity >= differenceInMonthsWithLastProgress
            ? actualProjectionMonthQuantity ===
              differenceInMonthsWithLastProgress
              ? actualProjectedAmount
              : (actualProjectedAmount += monthlyContributions)
            : null,
        x: actualProjectionMonthQuantity++,
      });
    } while (actualProjectedAmount < targetAmount);

    return [
      {
        color: "#475A6D",
        data: originalProjectionData,
        lineWidth: 5,
        name: "Original projection",
        type: "line",
      },
      {
        color: "#F01718",
        dashStyle: "ShortDash",
        data: actualProjectionData,
        lineWidth: 5,
        name: "Actual projection",
        type: "line",
      },
    ];
  };

  const getProgressSeries = () => {
    const covered = [];
    const shortfall = [];

    const daysFromGoalCreation = dayjs().diff(
      dayjs(goalCreationTimestamp),
      "day"
    );
    const monthsFromGoalCreation = Math.floor(daysFromGoalCreation / 30);
    const monthlyContributions = goalData?.contributions / 12;
    let savedAmount =
      (lastProgressData?.actualTotal ?? goalData?.investmentAmount) +
      monthlyContributions * (monthsFromGoalCreation + 1);

    for (let i = 0; i < investmentMonthQuantity; i++) {
      if (savedAmount) {
        if (savedAmount >= monthlyExpenses) {
          covered[i] = monthlyExpenses;
          savedAmount -= monthlyExpenses;
          shortfall[i] = 0;
        } else {
          covered[i] = savedAmount;
          shortfall[i] = monthlyExpenses - savedAmount;
          savedAmount = 0;
        }
      } else {
        covered[i] = 0;
        shortfall[i] = monthlyExpenses;
      }
    }

    return [
      {
        color: "#EBEBEB",
        data: shortfall,
        name: "Original goal",
        type: "column",
      },
      {
        color: "#34AF7A",
        data: covered,
        name: "Actual progress",
        type: "column",
      },
    ];
  };

  const getShortfallValue = () =>
    getProgressSeries()
      ?.find(it => it.name === "Original goal")
      ?.data?.reduce((acc, it) => acc + it, 0);

  const isProjectionChartYearsView =
    getProjectionSeries()[0].data.length >= 36 ||
    getProjectionSeries()[1].data.length >= 36;

  const projectionChartConfig = {
    chart: {
      height: 330,
      spacingTop: 40,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      align: "right",
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
      column: {
        stacking: "normal",
      },
    },
    series: getProjectionSeries(),
    title: {
      text: null,
    },
    tooltip: {
      formatter: function () {
        return `Month ${this.x}: ${getCurrencyValue(this.y)}`;
      },
    },
    xAxis: {
      allowDecimals: false,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Inter, sans-serif",
          color: "#516474",
        },
        formatter: e =>
          isProjectionChartYearsView
            ? `Year ${e.value / 12}`
            : `Month ${e.value}`,
      },
      tickInterval: isProjectionChartYearsView ? 12 : 1,
    },
    yAxis: {
      opposite: true,
      title: false,
    },
  };

  const progressChartConfig = {
    chart: {
      type: "column",
      height: 330,
      spacingTop: 40,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      align: "right",
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
      column: {
        stacking: "normal",
      },
    },
    series: getProgressSeries(),
    title: {
      text: null,
    },
    tooltip: {
      formatter: function () {
        return `Month ${this.x + 1}: ${getCurrencyValue(this.y)}`;
      },
    },
    xAxis: {
      allowDecimals: false,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Inter, sans-serif",
          color: "#516474",
        },
        formatter: e => `Month ${e.value + 1}`,
      },
      tickInterval: 1,
    },
    yAxis: {
      opposite: true,
      title: false,
    },
  };

  return {
    goalCreationTimestamp,
    goalFundedValue: getGoalFundedValue(),
    progressChartConfig,
    projectionChartConfig,
    projectionSeries: getProjectionSeries(),
    progressSeries: getProgressSeries(),
    shortfallValue: getShortfallValue(),
    targetAmount,
  };
};
