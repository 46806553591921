import logo_oneascent from "../image/logo.svg";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#009BDE",
  colorPrimaryButtonText: "#FFFFFF",
  logoSrc: logo_oneascent,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  pageLoginButtonSubmitColor: "#192849",
  proposalHeaderBackground:
    "radial-gradient(41.33% 85.87% at 81.47% 36.73%, #1799D5 0%, #E5F2F9 100%)",
  proposalHeaderPadding: "30px 52px 30px 43px",
  sidebarBackground: "linear-gradient(180deg, #15284B 0, #009BDE 100%)",
  sidebarIconColor: "#fff",
  sidebarIconColorActive: "#74C2E6",
  sidebarMenuItemFontSize: "12px",
  sidebarMenuItemHoverBackground: "#152A4E",
  sidebarMenuItemPadding: "9px",
  tenantColorPrimary: "#009BDE",
};
