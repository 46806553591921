import {
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../../utils/helpers/specialized";

export const getRecommendedProductId = ({
  organization,
  productsList,
  proposalData,
}) => {
  if (isOrganizationOneascent(organization)) {
    let productsNames = [];

    if (proposalData?.investmentAmount <= 499999) {
      productsNames = [
        "OneAscent Peak Equity Core",
        "OneAscent Peak Growth Core",
        "OneAscent Peak Moderate Core",
        "OneAscent Peak Conservative Core",
        "OneAscent Peak Preservation Core",
      ];
    } else {
      productsNames = [
        "OneAscent Peak Equity Elite",
        "OneAscent Peak Growth Elite",
        "OneAscent Peak Moderate Elite",
        "OneAscent Peak Conservative Elite",
        "OneAscent Peak Preservation Elite",
      ];
    }

    const recommendedProductName = productsNames.find(it =>
      it.toLowerCase().includes(proposalData?.riskTolerance?.toLowerCase())
    );

    return productsList.find(it => it.name === recommendedProductName)?._id;
  } else if (isOrganizationRivershares(organization)) {
    return productsList.find(
      it => it.riskTolerance === proposalData?.riskTolerance
    )?._id;
  } else {
    return (
      productsList.find(it => it.riskTolerance === proposalData?.riskTolerance)
        ?._id ?? productsList[0]?._id
    );
  }
};
