import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Col, Flex, Row, theme } from "antd";
import CardProduct from "../../molecules/CardProduct";
import ChartComparisonGrowth from "../../molecules/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../molecules/ChartIncomeWithTypes";
import CompareCardPlaceholder from "./components/CompareCardPlaceholder";

import { MAX_COMPARE_PRODUCTS } from "../../utils/constants";

import { getLiveAssessment } from "../../utils/requests/regularApp";

const DashboardCompare = ({
  closeCompareMode,
  handleCardClick,
  isCompareChartDirty,
  openProductsListModal,
  productsList,
  proposalData,
  setIsCompareChartDirty,
  state,
  setState,
  showError,
  showLegend,
  showSecondaryButton,
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [productsLiveAssessment, setProductsLiveAssessment] = useState([]);

  useEffect(() => {
    if (state.compareProducts?.length) {
      if (isCompareChartDirty || !productsLiveAssessment.length) {
        setLoading(true);
        debouncedLiveAssessmentFetch();
      }
    }
  }, [isCompareChartDirty]);

  const debouncedLiveAssessmentFetch = useDebouncedCallback(async () => {
    const responses = [];

    const requestPromises = state.compareProducts.map((productId, index) => {
      const requestBody = {
        organization:
          (state.organization?.name?.toLowerCase() ??
            state.orgName?.toLowerCase()) === "level"
            ? "level2"
            : (state.organization?.name ?? state.orgName),
        productId: productId,
        objective: {
          ...proposalData,
          investmentDuration:
            proposalData.investmentDuration ?? proposalData.lengthOfInvestment,
        },
      };

      return getLiveAssessment(requestBody)
        .then(data => {
          responses.push({
            productId,
            ...data,
            color:
              state.compareProducts?.length > 1 &&
              token.comparisonProductsColors[index],
          });
        })
        .catch(({ response }) => {
          showError(response?.data?.error ?? response?.data?.message);
        });
    });

    await Promise.all(requestPromises);

    setProductsLiveAssessment(responses);
    setLoading(false);
    setIsCompareChartDirty(false);
  }, 2000);

  const ListPlaceholders = () => {
    if (state.loading) return;

    const freeSlotsAmount =
      MAX_COMPARE_PRODUCTS - state.compareProducts?.length;

    return Array.apply(null, Array(freeSlotsAmount)).map((ignore, index) => (
      <CompareCardPlaceholder key={index} handleClick={openProductsListModal} />
    ));
  };

  const handleCardCloseButton = productId => {
    setState(lastState => ({
      ...lastState,
      compareProducts: lastState.compareProducts.filter(it => it !== productId),
    }));
    setIsCompareChartDirty(true);
  };

  return (
    <>
      <Row gutter={[22, 22]} style={{ width: "100%" }}>
        <Col span={24}>
          <ChartComparisonGrowth
            height={233}
            loading={loading}
            productsList={productsList}
            productsLiveAssessment={productsLiveAssessment}
            proposalData={proposalData}
          />
        </Col>
        <Col span={24}>
          <ChartIncomeWithTypes
            loading={loading}
            productsList={productsList}
            productsLiveAssessment={productsLiveAssessment}
            productData={proposalData}
            showLegend={showLegend}
          />
        </Col>
      </Row>
      <p style={{ fontSize: 20, color: "#02494E", margin: " 38px 33px 14px" }}>
        Product Selections
      </p>
      <Flex gap={20} style={{ overflowX: "auto", width: "100%" }}>
        {state.compareProducts
          ?.map(id => productsList?.find(it => it._id === id))
          ?.map((product, index) => (
            <Flex key={product._id} vertical>
              <div
                style={{
                  background: token.comparisonProductsColors[index],
                  borderRadius: 23,
                  height: 9,
                  marginLeft: 8,
                  width: 67,
                }}
              />
              <CardProduct
                actions={[
                  {
                    buttonCopy: "Details",
                    key: "details",
                    onSelect: product => handleCardClick(product?._id),
                  },
                ]}
                product={product}
                handleCardClick={handleCardClick}
                labels={[product?.riskTolerance, product?.allocationType]}
                showCloseButton={index !== 0}
                handleCloseButtonClick={handleCardCloseButton}
                showSecondaryButton={showSecondaryButton}
                isSecondaryButtonActive={state.selectedProduct === product?._id}
                handleSecondaryButtonActivate={productId => {
                  setState(oldState => ({
                    ...oldState,
                    compareProducts: [productId],
                    selectedProduct: productId,
                  }));
                  closeCompareMode();
                }}
                width={"306px"}
              />
            </Flex>
          ))}
        <ListPlaceholders />
      </Flex>
    </>
  );
};

export default DashboardCompare;
