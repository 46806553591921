import { useContext } from "react";
import State from "../../../../context";
import { Button, Flex, Image, Layout, Row, theme } from "antd";
import ButtonDemoMode from "../../../../atoms/ButtonDemoMode";

import {
  isAdminView,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../utils/helpers/specialized";
import { ReactComponent as EyeOff } from "./images/eye_off.svg";
import { ReactComponent as EyeOn } from "./images/eye_on.svg";
import { ReactComponent as ArrowLeftBLue } from "../../../images/arrow_left_circle_blue.svg";
import { ReactComponent as OneAscentSlogan } from "../../../../ecosystems/oneascent/image/slogan_dark.svg";

const AdvisorHeader = ({ organization }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const toggleDashboardBlurred = dashboardBlurred =>
    state.setKeyValue("dashboardBlurred", dashboardBlurred);

  return (
    <>
      {isAdminView() && (
        <div
          style={{
            background: token.colorGrey2,
            color: "#fff",
            fontSize: 20,
            fontWeight: "bold",
            padding: 8,
            textAlign: "center",
          }}
        >
          Advisor view
        </div>
      )}
      <Layout.Header
        style={{
          background:
            location.pathname.includes("advisor-portal/dashboard") && "#fafafa",
          height: "fit-content",
          marginRight:
            location.pathname.includes("advisor-portal/dashboard") &&
            isOrganizationLevel(organization) &&
            140,
        }}
      >
        <Row justify={"space-between"}>
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
          <Flex align={"center"} gap={16}>
            {location.pathname.includes("/dashboard") ? (
              <>
                {!isOrganizationRivershares(organization) &&
                  (state.dashboardBlurred ? (
                    <EyeOff
                      className="pointer"
                      onClick={() => toggleDashboardBlurred(false)}
                    />
                  ) : (
                    <EyeOn
                      className="pointer"
                      onClick={() => toggleDashboardBlurred(true)}
                    />
                  ))}
                {isOrganizationOneascent(organization) && (
                  <Button
                    onClick={() => state.openModal("artBoardModal")}
                    shape={"round"}
                    size={"small"}
                    style={{
                      color: token.tenantColorPrimary,
                      borderColor: token.tenantColorPrimary,
                    }}
                  >
                    <Flex align={"center"} gap={8}>
                      <ArrowLeftBLue />
                      <span>Back to Launch</span>
                    </Flex>
                  </Button>
                )}
                <ButtonDemoMode
                  borderColor={token.tenantColorPrimary}
                  color={token.tenantColorPrimary}
                  organization={organization}
                  userManagerEmail={state._id}
                  width={106}
                />
                <Button
                  type="primary"
                  size="small"
                  shape="round"
                  style={{
                    color: token.colorPrimaryButtonText,
                    backgroundColor: token.colorPrimaryButton,
                  }}
                  onClick={() => {
                    state.setKeyValue("activeEmail", null);
                    state.setKeyValue("openModalAddManagedUser", true);
                  }}
                >
                  + Add New Client
                </Button>
              </>
            ) : (
              isOrganizationOneascent(organization) && (
                <OneAscentSlogan
                  id={"oneascentSlogan"}
                  style={{ width: 160 }}
                />
              )
            )}
          </Flex>
        </Row>
      </Layout.Header>
    </>
  );
};

export default AdvisorHeader;
