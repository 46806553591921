import styled from "styled-components";
import { Flex, Spin } from "antd";

const Container = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 2000;
`;

const Overlay = ({ loading, children }) => (
  <>
    {loading && (
      <Container align={"center"} justify={"center"}>
        <Spin />
      </Container>
    )}
    <div style={{ visibility: loading ? "hidden" : "" }}>{children}</div>
  </>
);

export default Overlay;
