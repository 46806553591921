import { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import State from "../../../../../../context";

import { LIST_PERSPECTIVES } from "../../../../constant";

import { getChartConfigPlanning } from "../../../../chart";

const ChartPersonality = ({ ...props }) => {
  const [state] = useContext(State);

  const perspectiveMy = state.getPreferenceValue("perspectiveMy");
  const perspectiveSpouse = state.getPreferenceValue("perspectiveSpouse");

  const getScore = points => Math.round((points / 25) * 100);

  const examplePersonalityData =
    props?.examplePersonality &&
    LIST_PERSPECTIVES.find(it => it.name === props.examplePersonality);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChartConfigPlanning({
        ...props,
        series: [
          {
            color: "#42C77D",
            data: [
              getScore(perspectiveMy?.calculation?.spendingPoints),
              getScore(perspectiveMy?.calculation?.givingPoints),
              getScore(perspectiveMy?.calculation?.savingPoints),
              getScore(perspectiveMy?.calculation?.investingPoints),
            ],
            hidden: !perspectiveMy?.calculation || props.hideUser,
            lineColor: "transparent",
            marker: {
              enabled: false,
            },
            name: state.personalInfo?.firstName,
            pointPlacement: "on",
            type: "area",
            zIndex: 1,
          },
          {
            color: "#4B82B1",
            data: [
              getScore(perspectiveSpouse?.calculation?.spendingPoints),
              getScore(perspectiveSpouse?.calculation?.givingPoints),
              getScore(perspectiveSpouse?.calculation?.savingPoints),
              getScore(perspectiveSpouse?.calculation?.investingPoints),
            ],
            hidden: !perspectiveSpouse?.calculation || props.hideSpouse,
            lineColor: "transparent",
            marker: {
              enabled: false,
            },
            name: state.getPreferenceValue("guideInitialData")?.spouseFirstName,
            pointPlacement: "on",
            type: "area",
            zIndex: 2,
          },
          {
            color: "#3D9ED5",
            dashStyle: "Dash",
            data: [
              examplePersonalityData?.spending,
              examplePersonalityData?.giving,
              examplePersonalityData?.saving,
              examplePersonalityData?.investing,
            ],
            hidden: !examplePersonalityData,
            marker: {
              enabled: false,
            },
            name: "Planning Profile",
            pointPlacement: "on",
            zIndex: 2,
          },
        ].filter(it => !it.hidden),
        width: props.width ?? 390,
      })}
    />
  );
};

export default ChartPersonality;
