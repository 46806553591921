import { Outlet, Route, Routes } from "react-router-dom";
import GlobalStateProvider from "./StateProvider";
import GoalsLayout from "../ecosystems/goals/layouts";
import LayoutManager from "../layouts/LayoutManager";
import LayoutPrimary from "../layouts/LayoutPrimary";
import LayoutProposal from "../layouts/LayoutProposal";
import MmiAdminStateProvider from "../ecosystems/mmi/context/admin/StateProvider";
import MmiAssetManagerLayout from "../ecosystems/mmi/templates/PortalAssetManager/layouts";
import MmiUserStateProvider from "../ecosystems/mmi/context/user/StateProvider";
import SuperAdminLayout from "../ecosystems/super_admin/layout";
import UI from "./UI";

import {
  isDomainAstor,
  isDomainBluepoint,
  isDomainHalo,
  isDomainLevel,
  isDomainMmi,
  isDomainOneascent,
  isDomainRivershares,
  isDomainSymetra,
} from "../utils/helpers/specialized";

import astorAdminPrivateRoutes from "../ecosystems/astor/routes/admin";
import astorAdvisorPrivateRoutes from "../ecosystems/astor/routes/advisor";
import astorProposalPrivateRoutes from "../ecosystems/astor/routes/proposal";
import astorPublicRoutes from "../ecosystems/astor/routes/public";
import bluepointAdminPrivateRoutes from "../ecosystems/bluepoint/routes/admin";
import bluepointGoalsUserPrivateRoutes from "../ecosystems/bluepoint/routes/goals";
import bluepointProposalPrivateRoutes from "../ecosystems/bluepoint/routes/proposal";
import bluepointPublicRoutes from "../ecosystems/bluepoint/routes/public";
import goalsUserPrivateRoutes from "../ecosystems/goals/routes/user";
import haloPublicRoutes from "../ecosystems/halo/routes/public";
import levelAdminPrivateRoutes from "../ecosystems/level/routes/admin";
import levelAdvisorPrivateRoutes from "../ecosystems/level/routes/advisor";
import levelPublicRoutes from "../ecosystems/level/routes/public";
import levelProposalPrivateRoutes from "../ecosystems/level/pages/PageLevelProposal/routes/user";
import mmiAdminPrivateRoutes from "../ecosystems/mmi/routes/admin";
import mmiPublicRoutes from "../ecosystems/mmi/routes/public";
import mmiUserPrivateRoutes from "../ecosystems/mmi/routes/user";
import nextAdvisorRoutes from "../ecosystems/next/routes";
import oneAscentAdminPrivateRoutes from "../ecosystems/oneascent/route/admin";
import oneAscentAdvisorPrivateRoutes from "../ecosystems/oneascent/route/advisor";
import oneAscentProposalPrivateRoutes from "../ecosystems/oneascent/route/proposal";
import oneAscentPublicRoutes from "../ecosystems/oneascent/route/public";
import publicRoutes from "../ecosystems/goals/routes/public";
import riversharesAdminPrivateRoutes from "../ecosystems/rivershares/routes/admin";
import riversharesAdvisorPrivateRoutes from "../ecosystems/rivershares/routes/advisor";
import riversharesProposalPrivateRoutes from "../ecosystems/rivershares/routes/proposal";
import riversharesPublicRoutes from "../ecosystems/rivershares/routes/public";
import symetraPublicRoutes from "../ecosystems/symetra/routes/public";
import superAdminPrivateRoutes from "../ecosystems/super_admin/routes/admin";

import themeBasic from "../theme/global";
import themeUserAstor from "../ecosystems/astor/themes/user";
import themeUserBluepoint from "../ecosystems/bluepoint/themes/user";
import themeUserOneascent from "../ecosystems/oneascent/theme/user";

const getPublicRoutes = () => {
  if (isDomainAstor()) return astorPublicRoutes;
  else if (isDomainBluepoint()) return bluepointPublicRoutes;
  else if (isDomainHalo()) return haloPublicRoutes;
  else if (isDomainLevel()) return levelPublicRoutes;
  else if (isDomainMmi()) return mmiPublicRoutes;
  else if (isDomainOneascent()) return oneAscentPublicRoutes;
  else if (isDomainRivershares()) return riversharesPublicRoutes;
  else if (isDomainSymetra()) return symetraPublicRoutes;
  else return publicRoutes;
};

const getPublicThemeConfig = () => {
  if (isDomainAstor()) return themeUserAstor;
  else if (isDomainBluepoint()) return themeUserBluepoint;
  else if (isDomainOneascent()) return themeUserOneascent;
  else return themeBasic;
};

const Routing = () => {
  const children = Object.entries(publicRoutes)
    .map(([path, Component]) => ({
      path,
      element: <Component key={path} />,
    }))
    .map(props => <Route key={props.path} {...props} />);

  return (
    <Routes>
      {Object.entries(getPublicRoutes()).map(([path, LayoutOutlet]) => (
        <Route
          key={path}
          path={path}
          element={
            <UI themeConfig={getPublicThemeConfig()}>
              <LayoutOutlet />
            </UI>
          }
        >
          {children}
        </Route>
      ))}
      <Route
        element={
          <GlobalStateProvider manager organization={"Level"}>
            <LayoutManager routes={levelAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {levelAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Level"}>
            <LayoutManager routes={levelAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {levelAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Astor"}>
            <LayoutPrimary routes={astorAdminPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {astorAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"OneAscent"}>
            <LayoutManager routes={oneAscentAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {oneAscentAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Bluepoint"}>
            <LayoutManager routes={bluepointAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {bluepointAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"RiverShares"}>
            <LayoutManager routes={riversharesAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {riversharesAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Astor"}>
            <LayoutPrimary routes={astorAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {astorAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"OneAscent"}>
            <LayoutManager routes={oneAscentAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {oneAscentAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"RiverShares"}>
            <LayoutManager routes={riversharesAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {riversharesAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"Level"}>
            <LayoutPrimary routes={levelProposalPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {levelProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"Goals"}>
            <GoalsLayout routes={goalsUserPrivateRoutes}>
              <Outlet />
            </GoalsLayout>
          </GlobalStateProvider>
        }
      >
        {goalsUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"Bluepoint"}>
            <GoalsLayout routes={bluepointGoalsUserPrivateRoutes}>
              <Outlet />
            </GoalsLayout>
          </GlobalStateProvider>
        }
      >
        {bluepointGoalsUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"Astor"}>
            <LayoutProposal routes={astorProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {astorProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"OneAscent"}>
            <LayoutProposal routes={oneAscentProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {oneAscentProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"Bluepoint"}>
            <LayoutProposal routes={bluepointProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {bluepointProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization={"RiverShares"}>
            <LayoutProposal routes={riversharesProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {riversharesProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <MmiUserStateProvider>
            <MmiAssetManagerLayout>
              <Outlet />
            </MmiAssetManagerLayout>
          </MmiUserStateProvider>
        }
      >
        {mmiUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <MmiAdminStateProvider>
            <Outlet />
          </MmiAdminStateProvider>
        }
      >
        {mmiAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Penny"}>
            <SuperAdminLayout routes={superAdminPrivateRoutes}>
              <Outlet />
            </SuperAdminLayout>
          </GlobalStateProvider>
        }
      >
        {superAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization={"Next"}>
            <LayoutPrimary>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {nextAdvisorRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
    </Routes>
  );
};

export default Routing;
