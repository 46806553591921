import { Image } from "antd";
import RadioButton from "../../component/RadioButton";

import footer from "./image/footer.svg";
import slogan from "./image/slogan.png";

const ReportPageIpq2 = ({ children, prospectObjective }) => (
  <div
    className={"page-container"}
    style={{
      color: "#2A2828",
      fontFamily: "Helvetica, sans-serif",
      height: 1590,
      position: "relative",
      width: 1230,
    }}
    id={"reportPageIpq2"}
  >
    <div
      style={{
        boxSizing: "border-box",
        padding: "42px 38px",
      }}
    >
      {children}
      <div style={{ color: "#1A3A63", fontSize: 20, marginTop: 25 }}>
        Risk Selection
      </div>
      <div
        style={{
          fontSize: 12,
          lineHeight: "16px",
          marginTop: 20,
          paddingLeft: 20,
        }}
      >
        <div>
          <b>A:</b> Your calculated risk score is{" "}
          <b style={{ textTransform: "capitalize" }}>
            {prospectObjective?.calculatedRiskTolerance ?? "--"}
          </b>
        </div>
        <div style={{ marginTop: 12 }}>
          <b>B:</b> You selected an investment with a goal of{" "}
          <b style={{ textTransform: "capitalize" }}>
            {prospectObjective?.riskTolerance ?? "--"}
          </b>
        </div>
        <div
          style={{
            alignItems: "center",
            gap: 16,
            display: "flex",
            marginTop: 16,
          }}
        >
          <RadioButton
            checked={prospectObjective?.acceptedRiskLevel === "recommended"}
          />
          <span>
            All accounts should be invested according to my measured risk level
            listed above <b>(A)</b>
          </span>
        </div>
        <div style={{ gap: 16, display: "flex", marginTop: 20 }}>
          <RadioButton
            checked={prospectObjective?.acceptedRiskLevel === "custom"}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              I wish to deviate from my measured risk level and request that my
              account be invested
              <br /> according to my selection above <b>(B)*</b>
            </span>
            {prospectObjective?.acceptedRiskLevel === "custom" && (
              <table style={{ borderSpacing: 0, marginTop: 12, width: 380 }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: "1px solid #70707050",
                        textAlign: "left",
                        width: 240,
                      }}
                    >
                      Account
                    </th>
                    <th
                      style={{
                        borderBottom: "1px solid #70707050",
                        textAlign: "left",
                        width: 140,
                      }}
                    >
                      Risk Tolerance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        maxWidth: 240,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {prospectObjective?.listAccounts?.account__name__1}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {prospectObjective?.listAccounts?.account__riskType__1}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        maxWidth: 240,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {prospectObjective?.listAccounts?.account__name__2}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {prospectObjective?.listAccounts?.account__riskType__2}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        maxWidth: 240,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {prospectObjective?.listAccounts?.account__name__3}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {prospectObjective?.listAccounts?.account__riskType__3}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        maxWidth: 240,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {prospectObjective?.listAccounts?.account__name__4}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {prospectObjective?.listAccounts?.account__riskType__4}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        maxWidth: 240,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {prospectObjective?.listAccounts?.account__name__5}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {prospectObjective?.listAccounts?.account__riskType__5}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <p style={{ margin: "54px 0 66px", paddingRight: 70 }}>
          <b>* Misaligned Portfolio Consent:</b> In the event that the portfolio
          selected above does not correspond to the measured risk tolerance
          information above, I understand that I have chosen a different
          portfolio despite the lack of alignment with risk tolerance and am
          comfortable with this choice and the risks associated with such
          choice, including the risk that I may lose principal. I further
          understand that it is my responsibility to communicate any changes in
          my circumstances, risk tolerance, or portfolio selection to my
          adviser. In addition, I will regularly review all account statements
          and reports from my adviser and alert my adviser if I have any
          questions, comments or concerns.
        </p>
        <div style={{ display: "flex", gap: 6 }}>
          <div
            style={{
              alignItems: "end",
              display: "flex",
              gap: 23,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>Client Name:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 230 }} />
            </div>
            <div style={{ display: "flex" }}>
              <div>Client Signature:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 230 }} />
            </div>
            <div style={{ display: "flex" }}>
              <div>Date:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 230 }} />
            </div>
            <div style={{ display: "flex" }}>
              <div>Advisor Signature:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 230 }} />
            </div>
          </div>
          <div
            style={{
              alignItems: "end",
              display: "flex",
              gap: 23,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>Secondary Client Name:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 250 }} />
            </div>
            <div style={{ display: "flex" }}>
              <div>Secondary Signature:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 250 }} />
            </div>
            <div style={{ display: "flex" }}>
              <div>Date:</div>
              <div style={{ borderBottom: "1px solid #707070", width: 250 }} />
            </div>
          </div>
        </div>
        <p style={{ marginTop: 52 }}>
          <b>Notes:</b>
        </p>
        <p style={{ paddingRight: 70 }}>{prospectObjective?.note}</p>
      </div>
    </div>
    <footer style={{ bottom: 0, position: "absolute", width: "100%" }}>
      <Image
        preview={false}
        src={slogan}
        style={{ margin: "0 0 17px 38px" }}
        width={183}
      />
      <Image preview={false} src={footer} width={"100%"} />
    </footer>
  </div>
);

export default ReportPageIpq2;
