import styled from "styled-components";
import { Flex } from "antd";

import selected_tab_marker from "./images/selected_tab_marker.svg";

const StylesContainer = styled(Flex)`
  margin: 45px auto;
  width: 962px;

  .header {
    margin-left: 12px;

    .label {
      color: #000;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      line-height: normal;
    }

    .title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }

  .tab-container {
    height: 485px;
    overflow: auto;

    .tab {
      background: #ebebeb;
      border-radius: 8px;
      color: #3c3c3c;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 12px;
      padding: 16px;
      position: relative;
      width: 168px;

      &.active {
        background: #6e7d83;
        color: #fff;
      }

      &.selected::before {
        background: url(${selected_tab_marker}) left no-repeat;
        content: "";
        height: 33px;
        left: -12px;
        position: absolute;
        top: 0;
        width: 30px;
      }

      .title {
        font-size: 19px;
        line-height: 140%;
        margin: 0;
      }

      .description {
        font-weight: 500;
        line-height: 140%;
        margin: 0;
      }
    }
  }

  .content-container {
    background: #f7f7f7;
    border-radius: 12px;
    padding: 28px;
    width: 767px;

    .description-container {
      width: 358px;

      .label {
        color: #7f99b2;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
      }

      .title {
        color: #1e1e1e;
        font-size: 40px;
        line-height: 115%;
        margin: 0 0 16px;
      }

      .description {
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
`;

export default StylesContainer;
