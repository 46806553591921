import { Image } from "antd";

import radio_checked from "./image/radio_checked.svg";

const RadioButton = ({ checked }) =>
  checked ? (
    <Image src={radio_checked} style={{ minWidth: 18 }} />
  ) : (
    <div
      style={{
        border: "2px solid #8396A6",
        borderRadius: "50%",
        boxSizing: "border-box",
        height: 18,
        minWidth: 18,
        width: 18,
      }}
    />
  );

export default RadioButton;
