import PageAdvisorDashboard from "../../../pages/PageAdvisorDashboard";
import PageLevelAdvisorClients from "../../level/pages/PageLevelAdvisorClients";
import PageMarketing from "../../../pages/PageMarketing";
import PageProducts from "../../../pages/PageProducts";
import PageProspects from "../../../pages/PageProspects";

import BoxOpen from "../../../icons/BoxOpen";
import DocumentPage from "../../../icons/DocumentPage";
import User from "../../../icons/User";
import Users from "../../../icons/Users";

export default [
  {
    path: "/rivershares-advisor-portal/dashboard",
    Component: PageAdvisorDashboard,
    Icon: User,
    text: "Dashboard",
  },
  {
    path: "/rivershares-advisor-portal/prospects",
    Component: PageProspects,
    Icon: Users,
    text: "Prospects",
  },
  {
    path: "/rivershares-advisor-portal/clients",
    Component: PageLevelAdvisorClients,
    Icon: Users,
    text: "Clients",
  },
  {
    path: "/rivershares-advisor-portal/products",
    Component: PageProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/rivershares-advisor-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
];
