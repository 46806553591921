import { Flex, Image } from "antd";
import Header from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { EMPTY_UNIQUE_TENDENCIES_TEXT } from "../../../page/PageProposalDashboard/components/SectionPerspectiveCoupleResults/components/TabUniqueTendencies";

import { getListUseOfMoney, LIST_PERSPECTIVES } from "../../../constant";

import demo from "./image/demo.png";

const ReportPagePersonalityUnique = ({
  coupleCalculations,
  personaName,
  selectedPerspectiveUser,
  selectedPerspectiveSpouse,
  spouseFirstName,
  userFirstName,
}) => {
  const uniqueTendencyTypes = coupleCalculations
    .filter(it => it.difference > 50)
    .map(it => it.type);

  return (
    <StylesContainer id={"reportPagePersonalityUnique"}>
      <div className={"page-container"}>
        <Header personaName={personaName} />

        <main>
          <div>
            <h3 className={"title"}>Unique Tendencies</h3>
            <h3 className={"subtitle"}>Where your tendencies diverge</h3>
          </div>

          <p>
            Each spouse brings something vital to the table. Our differences can
            bring depth and order to our life together, keeping all things
            interesting and well-rounded.
          </p>

          <p>
            {LIST_PERSPECTIVES.find(
              it => it.name === selectedPerspectiveUser
            )?.uniqueTendenciesCopy?.replace(
              "[userName]",
              `(${userFirstName})`
            )}{" "}
            {LIST_PERSPECTIVES.find(
              it => it.name === selectedPerspectiveSpouse
            )?.uniqueTendenciesCopy?.replace(
              "[userName]",
              `(${spouseFirstName})`
            )}
          </p>

          <Flex gap={26}>
            <Image preview={false} src={demo} />

            <div className={"analytic-container"}>
              {uniqueTendencyTypes.length ? (
                <Flex gap={20} vertical>
                  {uniqueTendencyTypes.map((tendencyType, index) => {
                    const useOfMoneyData = getListUseOfMoney(
                      userFirstName,
                      spouseFirstName
                    ).find(it => it.type === tendencyType);

                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: useOfMoneyData?.descriptionUniqueTendencies,
                        }}
                        key={index}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: EMPTY_UNIQUE_TENDENCIES_TEXT,
                  }}
                />
              )}
            </div>
          </Flex>

          <h3 className={"title"}>Conclusion</h3>

          <p>
            By embracing each partner's natural tendencies and accounting for
            any gaps, we can create a financial plan that fosters deep unity on
            the journey toward successful stewardship.
          </p>
        </main>
      </div>
    </StylesContainer>
  );
};

export default ReportPagePersonalityUnique;
