import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  font-family: Roboto, sans-serif;

  .title {
    color: #071a4d;
    font-size: 22px;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
    text-align: center;
  }

  .ant-progress {
    height: 21px;
    text-align: center;

    .ant-progress-inner {
      padding: 3px;
    }
  }

  .value-row {
    background: #fff;
    border: 1px solid #81b6dc;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 11px 16px 11px 32px;
    width: 100%;

    .index {
      color: #1c5d9a;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .value {
      color: #222;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .icon-reorder {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.1;
    }
  }
`;

export default StylesContainer;
