import { lazy } from "react";

const PageLogin = lazy(() => import("../../goals/pages/PageLogin"));
const PageNotFound = lazy(() => import("../../../pages/PageNotFound"));
const PageWelcome = lazy(() => import("../pages/PageWelcome"));

export default {
  login: PageLogin,
  "": PageWelcome,
  "*": PageNotFound,
};
