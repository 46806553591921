import PageProposalGoals from "../../proposal/page/PageProposalGoals";

import IconGoals from "../../../icons/IconGoals";

export default [
  {
    path: "/bluepoint-proposal",
    Component: PageProposalGoals,
    Icon: IconGoals,
    text: "Goals",
  },
];
