import { Flex } from "antd";
import Header from "../../component/ReportHeader";

import StylesContainer from "./styles";

const ReportPageProposalIncome = ({ personaName }) => (
  <StylesContainer id={"reportPageProposalIncome"}>
    <Flex className={"page-container"} gap={29} vertical>
      <Header personaName={personaName} />
      <h2>ReportPageProposalIncome</h2>
    </Flex>
  </StylesContainer>
);

export default ReportPageProposalIncome;
